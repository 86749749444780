






















import Button from "@/components/shared/Button.vue";
import Container from "@/components/shared/Container.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IEntityInfo } from "../../types";

/**
 * Responsible for rendering a "feedback" widget.
 */
@Component({
  components: { Container, Button }
})
export default class Feedback extends Vue {}
