








































import { Component, Prop, Vue } from "vue-property-decorator";
import { ITag, IAction } from "../../types";
import Button from "./Button.vue";

/**
 * Responsible for rendering a "entity summary" widget.
 */
@Component({ components: { Button } })
export default class EntitySummary extends Vue {
  /**
   * The source for the image.
   */
  @Prop({ default: "" })
  public imgSrc!: string; // TODO: Make it so the image is optional.

  /**
   * The alternative text to display if the image can not load or to users with screen readers.
   */
  @Prop({ default: "" })
  public imgAlt!: string;

  /**
   * The title for the entity.
   */
  @Prop({ default: "" })
  public title!: string;

  /**
   * The subtitle for the entity.
   */
  @Prop({ default: "" })
  public subtitle!: string;

  /**
   * The description for the property.
   */
  @Prop({ default: "" })
  public description!: string;

  /**
   * The meta data for the property.
   */
  @Prop({ default: "" })
  public metaData!: string;

  /**
   * The tags to apply to this summary.
   */
  @Prop({ default: [] })
  public tags!: ITag[];

  /**
   * The actions that appear for this summary.
   */
  @Prop({ default: [] })
  public actions!: IAction[];

  /**
   * The link to use in the title.
   */
  @Prop({ default: null })
  public href!: string;

  /**
   * Indicates if a link is available.
   * @return True if there is a link, false otherwise.
   */
  public get hasLink(): boolean {
    return this.href === null ? false : true;
  }

  /**
   * Returns the style for the given tag.
   * @param tag The tag to get the style for.
   * @return The style.
   */
  private getStyleForTag(tag: ITag): string {
    return (
      `color: rgba(${tag.colorText.r}, ${tag.colorText.g}, ${tag.colorText.b}, ${tag.colorText.a});` +
      `background: rgba(${tag.colorBackground.r}, ${tag.colorBackground.g}, ${tag.colorBackground.b}, ${tag.colorBackground.a});`
    );
  }

  /**
   * Returns the style for the given action.
   * @param action The action to get the style for.
   * @return The style.
   */
  private getStyleForAction(action: IAction): string {
    return (
      `color: rgba(${action.colorText.r}, ${action.colorText.g}, ${action.colorText.b}, ${action.colorText.a});` +
      `background: rgba(${action.colorBackground.r}, ${action.colorBackground.g}, ${action.colorBackground.b}, ${action.colorBackground.a});`
    );
  }

  /**
   * Callback that responds to the action being clicked.
   * @param event The object that enables interaction with the event.
   * @param action The action that was clicked.
   */
  private onClickAction(event: Event, action: IAction) {
    if (action.callback) action.callback(event, action);
  }
}
