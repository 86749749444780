

















import Button, { IClickHandler } from "./Button.vue";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

/**
 * Responsible for handling an overlay component.
 */
@Component({ components: { Button } })
export default class Modal extends Vue {
  /**
   * The text to display on the title bar.
   */
  @Prop({ default: "" })
  public title!: string;

  /**
   * The callback that responds to a click event on the cancel button.
   */
  @Prop({ default: null })
  public clickCancel!: IClickHandler;

  /**
   * The callback that responds to a click event on the accept button.
   */
  @Prop({ default: null })
  public clickAccept!: IClickHandler;

  /**
   * The text to display on the cancel button.
   */
  @Prop({ default: "Cancel" })
  public textCancel!: string;

  /**
   * The text to display on the accept button.
   */
  @Prop({ default: "Accept" })
  public textAccept!: string;

  /**
   * A flag that indicates if this modal is visible.
   */
  @Prop({ default: true })
  public visible!: boolean;

  // The id of a previous timeout.
  private timeoutId_: number | null = null;
  private style_: string = "opacity: 0";

  /**
   * Callback that responds to the component being mounted.
   */
  public mounted() {
    // Move the modal to be the last element of the body.
    let parentElement = this.$el.parentElement;
    if (parentElement === null)
      throw new Error("The component is in an invalid state.");
    let bodyElement = document.querySelector("#app"); // TODO: Don't assume #app
    if (bodyElement === null || !(bodyElement instanceof HTMLElement))
      throw new Error("The component is in an invalid state.");
    parentElement.removeChild(this.$el);
    if (this.visible) {
      this.style_ = "opacity: 1";
      bodyElement.appendChild(this.$el);
    }
  }

  /**
   * Responds to changes in the visible state.
   */
  @Watch("visible")
  private onVisibleChanged(newValue: boolean) {
    // Clear a previous animation.
    if (this.timeoutId_ !== null) {
      clearTimeout(this.timeoutId_);
      this.timeoutId_ = null;
    }

    // Show immediatly if possible.
    if (newValue) {
      let bodyElement = document.querySelector("#app");
      if (bodyElement === null || !(bodyElement instanceof HTMLElement))
        throw new Error("The component is in an invalid state.");
      bodyElement.appendChild(this.$el);
      if (this.$el instanceof HTMLElement) {
        this.style_ = "opacity: 0";
        this.timeoutId_ = setTimeout(() => (this.style_ = "opacity: 1"), 1);
      }
    } else {
      // Otherwise hide after .5s
      this.style_ = "opacity: 0";
      this.timeoutId_ = setTimeout(() => {
        let parentElement = this.$el.parentElement;
        if (parentElement === null)
          throw new Error("The component is in an invalid state.");
        parentElement.removeChild(this.$el);
      }, 500);
    }
  }

  /**
   * Callback that responds to a user clicking the cancel button.
   * @param event The object that enables interaction with the event.
   */
  private onClickCancel(event: Event) {
    // Notify the user and cancel default behaviour if needed.
    if (this.clickCancel) this.clickCancel(event);
    if (event.defaultPrevented) return;
    this.$emit("update:visible", false);
  }

  /**
   * Callback that responds to a user clicking the cancel button.
   * @param event The object that enables interaction with the event.
   */
  private onClickAccept(event: Event) {
    // Notify the user and cancel default behaviour if needed.
    if (this.clickAccept) this.clickAccept(event);
    if (event.defaultPrevented) return;
    this.$emit("update:visible", false);
  }
}
