
import ConnectionPane from "@/components/profile/ConnectionPane.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IConnectionPaneParams } from "./ConnectionPane.vue";
import { IAction, IConnectionInfo, ITag } from "../../types";

/**
 * Responsible for rendering a "experience" widget.
 */
@Component
export default class Experience extends ConnectionPane {
  // The parameters required to run this pane.
  protected parameters_: IConnectionPaneParams = {
    name: "Team",
    plural: "Team Members",
    type: "team",
    filter: "person-or-email",
    entityMustExist: false,
    includeIncoming: true,
    includeOutgoing: true,
    conflictResolution: "to",
    metaDataInfo: [
      {
        propertyName: "position",
        label: "Position *",
        field: "text",
        colspan: 2,
        getter: c => c.position
      },
      {
        propertyName: "startDate",
        label: "Start Date *",
        field: "date",
        colspan: 1,
        getter: c => c.startDate
      },
      {
        propertyName: "endDate",
        label: "End Date *",
        field: "date",
        colspan: 1,
        getter: c => c.endDate
      },
      {
        propertyName: "description",
        label: "Description *",
        field: "textarea",
        colspan: 2,
        getter: c => c.description
      }
    ]
  };

  /**
   * Returns the actions for the given connection.
   * @param connection The connection to get the actions for.
   * @return The actions for the given connection.
   */
  protected getActions(connection: IConnectionInfo): IAction[] {
    let actions: IAction[] = [];
    let canEdit = this.authorized;
    let canVerify = this.authorized;

    // An organization can edit any meta data.
    if (canEdit) {
      actions.push({
        label: "Edit Experience",
        tag: "edit",
        colorText: { r: 255, g: 255, b: 255, a: 1 },
        colorBackground: { r: 113, g: 106, b: 106, a: 1 },
        callback: (event, action) =>
          this.onClickAction(event, connection, action)
      });
    }
    // We can only verify if the connection is incoming.
    if (
      canVerify &&
      connection.bidirectional === false &&
      connection.to.id === this.entity.id
    ) {
      actions.push({
        label: "Verify",
        tag: "verify",
        colorText: { r: 255, g: 255, b: 255, a: 1 },
        colorBackground: { r: 113, g: 106, b: 106, a: 1 },
        callback: (event, action) =>
          this.onClickAction(event, connection, action)
      });
    }
    // If we are the owner of this request or the connection is bidirectional, we can delete the connection.
    if (
      (connection.bidirectional === true ||
        connection.from.id === this.entity.id) &&
      canEdit
    ) {
      actions.push({
        label: "Cancel",
        tag: "delete",
        colorText: { r: 255, g: 255, b: 255, a: 1 },
        colorBackground: { r: 204, g: 51, b: 0, a: 1 },
        callback: (event, action) =>
          this.onClickAction(event, connection, action)
      });
    }
    return actions;
  }

  /**
   * Returns the tags for the given connection.
   * @param connection The connection to get the tags for.
   * @return The tags for the given connection.
   */
  protected getTags(connection: IConnectionInfo): ITag[] {
    let tags: ITag[] = [];
    let claims = this.entity.claims ? this.entity.claims : [];

    // Add the unverified tag.
    if (connection.bidirectional === false) {
      tags.push({
        label: "Unverified",
        href: "",
        colorText: { r: 0, g: 0, b: 0, a: 1 },
        colorBackground: { r: 190, g: 190, b: 190, a: 1 }
      });
    }

    // Add the owner tag.
    if (
      (this.entity.id === connection.from.id &&
        claims.some(c => c === connection.to.id)) ||
      (this.entity.id === connection.to.id &&
        claims.some(c => c === connection.from.id))
    ) {
      tags.push({
        label: "Owner",
        href: "",
        colorText: { r: 0, g: 0, b: 0, a: 1 },
        colorBackground: { r: 190, g: 190, b: 190, a: 1 }
      });
    }
    return tags;
  }

  /**
   * Callback that responds to an action button being clicked.
   * @param event The object that enables interaction with the event.
   * @param connection The connection that the action is connected to.
   * @param action The action that was clicked.
   */
  private onClickAction(
    event?: Event,
    connection?: IConnectionInfo,
    action?: IAction
  ): void {
    if (!event || !connection || !action) return;
    switch (action.tag) {
      case "edit":
        this.editConnection(connection);
        break;
      case "delete": {
        if (
          connection.bidirectional === true &&
          connection.to.id === this.entity.id
        ) {
          if (connection.otherConnection === null)
            throw new Error(
              "The connection is bidirectional but no other connection could be found"
            );
          else this.removeConnection(connection.otherConnection);
        } else this.removeConnection(connection);
        break;
      }
      case "verify":
        this.verifyConnection(connection);
        break;
      default:
        throw new Error(`Unexpected action '${action.tag}' encountered.`);
    }
  }
}
