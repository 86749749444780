import {
  IEntityInfo,
  IApiResponse,
  IApiResponseWithData,
  INeo4jUserImportExportData,
  INeo4jOrganizationImportExportData,
  IImageUpload
} from "@/types";
import Constants from "./Constants";
import ServiceHelper from "./ServiceHelper";

/**
 * The class used to query the back-end api for entity info.
 */
export default abstract class EntityServices {
  /**
   * Queries for the currently logged in entity.
   * @return The entity.
   */
  public static async getCurrentEntity(): Promise<
    IApiResponseWithData<IEntityInfo>
  > {
    return ServiceHelper.processResponse(
      ServiceHelper.get(Constants.endpoints.entity)
    );
  }

  /**
   * Queries for the currently logged in entity.
   * @param id The id of the entity to fetch.
   * @return The entity.
   */
  public static async getEntity(
    id: number
  ): Promise<IApiResponseWithData<IEntityInfo>> {
    return ServiceHelper.processResponse(
      ServiceHelper.get(`${Constants.endpoints.entity}${id}`)
    );
  }

  /**
   * Sends updated information for the specified entity.
   * @param entity The entity to update.
   * @return True if the update was successful, false otherwise.
   */
  public static async updateEntity(entity: IEntityInfo): Promise<IApiResponse> {
    // TODO: Enable the user to specify which fields have been updated.
    return ServiceHelper.processResponse(
      ServiceHelper.post(Constants.endpoints.updateEntity, entity)
    );
  }

  /**
   * Requests the number of entities of the specified type.
   * @param type The type of entity to query for.
   * @return The entity count.
   */
  public static async getEntityCount(
    type: string | null = null
  ): Promise<IApiResponseWithData<number>> {
    let query = type === null ? "" : `?type=${type}`;
    return ServiceHelper.processResponse(
      ServiceHelper.get(`${Constants.endpoints.entityCount}${query}`)
    );
  }

  /**
   * Updates the profile image for the specified entity.
   * @param entityId The id of the entity to attach the image to.
   * @param data The data that describes the image.
   */
  public static async uploadProfileImage(
    entityId: number,
    data: ArrayBuffer
  ): Promise<IApiResponseWithData<IImageUpload>> {
    return ServiceHelper.processResponse(
      ServiceHelper.post(
        Constants.endpoints.uploadProfileImage,
        new Uint8Array(data),
        {
          headers: {
            "Content-Type": "application/octet-stream",
            "entity-id": entityId
          }
        }
      )
    );
  }

  /**
   * Sends user information for import to the database.
   * @param entity The entity to import.
   * @return The response.
   */
  public static async importEntity(
    entity: INeo4jUserImportExportData | INeo4jOrganizationImportExportData
  ): Promise<IApiResponse> {
    return ServiceHelper.processResponse(
      ServiceHelper.post(Constants.endpoints.importEntity, entity)
    );
  }

  /**
   * Sends user profile information for import.
   * @param entityImportId The id of the entity to attach the image to.
   * @param data The data that describes the image.
   */
  public static async importProfileImage(
    entityImportId: string,
    data: ArrayBuffer
  ): Promise<IApiResponseWithData<IImageUpload>> {
    return ServiceHelper.processResponse(
      ServiceHelper.post(
        Constants.endpoints.importProfileImage,
        new Uint8Array(data),
        {
          headers: {
            "Content-Type": "application/octet-stream",
            "import-id": entityImportId
          }
        }
      )
    );
  }

  /**
   * Makes a request to claim ownership of an entity.
   * @param fromId The id of the person claiming the an entity.
   * @param toId The id of the entity being claimed.
   * @return A response indicating success or failure.
   */
  public static async claimEntity(
    fromId: number,
    toId: number
  ): Promise<IApiResponse> {
    let data = {
      fromId: fromId,
      toId: toId
    };
    return ServiceHelper.processResponse(
      ServiceHelper.post(Constants.endpoints.claimEntity, data)
    );
  }

  /**
   * Makes a request to unclaim ownership of an entity.
   * @param fromId The id of the person renouncing ownership the entity.
   * @param toId The id of the entity being unclaimed.
   * @return A response indicating success or failure.
   */
  public static async unclaimEntity(
    fromId: number,
    toId: number
  ): Promise<IApiResponse> {
    let data = {
      fromId: fromId,
      toId: toId
    };
    return ServiceHelper.processResponse(
      ServiceHelper.post(Constants.endpoints.unclaimEntity, data)
    );
  }

  /**
   * Returns all known claims of ownership for an entity.
   * @param id The id of the entity to find claims for.
   * @return A list of all entities that have claims.
   */
  public static async getClaims(
    id: number
  ): Promise<IApiResponseWithData<IEntityInfo[]>> {
    let queryParams = `?id=${id}`;
    return ServiceHelper.processResponse(
      ServiceHelper.get(`${Constants.endpoints.getClaims}${queryParams}`)
    );
  }
}
