
















































































































import "cropperjs/dist/cropper.css";
import VueCropper from "vue-cropperjs";
import Modal from "@/components/shared/Modal.vue";
import Container from "@/components/shared/Container.vue";
import Button from "@/components/shared/Button.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IEntityInfo, IRgba } from "../../types";
import EntityServices from "../../services/EntityServices";

/**
 * Responsible for rendering a "latest updates" widget.
 */
@Component({ components: { Container, Button, Modal, VueCropper } })
export default class LatestUpdates extends Vue {
  /**
   * The color to use for this profile.
   */
  @Prop({
    default: () => {
      return { r: 0, g: 202, b: 228, a: 1.0 };
    }
  })
  public color!: IRgba;

  /**
   * A flag that indicates if the current user can make edits to this profile.
   */
  @Prop({ default: false })
  public authorized!: boolean;

  /**
   * The entity to render information for.
   */
  @Prop({ default: null })
  public entity!: IEntityInfo;

  // Describes the entity being edited.
  private editMode_: boolean = false;
  private editEntity_: IEntityInfo | null = null;

  // Describes a profile image being edited.
  private showModal_: boolean = false;
  private editImageSrc_: string = "";
  private croppedImageSrc_: string = "";

  /**
   * The class name for the layout.
   * @return The class name for the layout.
   */
  public get layoutClassName(): string {
    return this.editMode_ === true ? "layout edit-mode" : "layout";
  }

  /**
   * The color of the admin button.
   * @return The color.
   */
  public get adminButtonColor(): IRgba {
    return { r: 204, g: 51, b: 0, a: 1.0 };
  }

  /**
   * The title to display for this component.
   * @return The title.
   */
  public get title(): string {
    switch (this.entity.type) {
      case "person":
        return "Member Profile";
      case "organization":
        return "Organization Profile";
      default:
        return "Profile";
    }
  }

  /**
   * Indicates if the profile is for the currently logged in entity.
   * @return True if this is the current entity's profile, false otherwise.
   */
  public get isCurrentEntity(): boolean {
    if (this.entity === null) return false;
    return this.$store.state.currentEntity.id === this.entity.id;
  }

  /**
   * Returns the source for the profile image.
   * @return The source image.
   */
  public get profileImageSrc(): string {
    if (this.croppedImageSrc_ !== "") return this.croppedImageSrc_;
    if (this.entity.imgSrc === undefined || this.entity.imgSrc === "")
      return this.$store.getters.imageSrc("profile-placeholder-member.svg");
    else return this.$store.getters.profileSrc(this.entity.imgSrc);
  }

  /**
   * Returns the text to display on the profile change button.
   * @return The text.
   */
  public get profileHeading(): string {
    switch (this.entity.type) {
      case "person":
        return `${this.entity.firstName} ${this.entity.lastName}`;
      case "organization":
        return this.entity.name;
      default:
        return "Unknown";
    }
  }

  /**
   * Returns the location for the person.
   * @return the location.
   */
  public get location(): string {
    if (this.entity === null) return "";
    let parts: string[] = [];
    if ("city" in this.entity && this.entity.city.trim() !== "")
      parts.push(this.entity.city.trim());
    if ("province" in this.entity && this.entity.province.trim() !== "")
      parts.push(this.entity.province.trim());
    if ("country" in this.entity && this.entity.country.trim() !== "")
      parts.push(this.entity.country.trim());
    if (parts.length === 0) return "";
    return parts.join(", ");
  }

  /**
   * Returns the statistics for an organization.
   * @return the statistics.
   */
  public get statistics(): string {
    if (this.entity === null) return "";
    let parts: string[] = [];
    if ("employees" in this.entity && this.entity.employees.trim() !== "")
      parts.push(this.entity.employees.trim() + " Employees");
    if ("visibility" in this.entity && this.entity.visibility !== "") {
      switch (this.entity.visibility) {
        case "public":
          parts.push("Public organization");
          break;
        case "private":
          parts.push("Private organization");
          break;
      }
    }
    if ("founded" in this.entity && this.entity.founded.trim() !== "")
      parts.push("Founded " + this.entity.founded.trim());
    if (parts.length === 0) return "";
    return parts.join(", ");
  }

  /**
   * Returns the text to display on the profile change button.
   * @return The text.
   */
  public get profileImageChangeText(): string {
    switch (this.entity.type) {
      case "person":
        return "Edit Photo";
      case "organization":
        return "Edit Logo";
      default:
        return "Edit Photo";
    }
  }

  /**
   * Converts the specified input to html.
   * @param input The string to convert.
   * @return The HTML.
   */
  public get descriptionHtml(): string {
    if (this.entity === null || !("description" in this.entity)) return "";
    let lines = this.entity.description.split("\n");
    let output = "";
    let isList = false;
    for (let line of lines) {
      if (line.trim().length === 0) continue;
      // If this is a bulleted item ...
      if (line.startsWith("\u2022")) {
        if (!isList) {
          output += "<ul>";
          isList = true;
        }
        output += "<li>" + line.substring(1).trim() + "</li>";
      }
      // If this is a regular item ...
      else {
        if (isList) {
          output += "</ul>";
          isList = false;
        }
        output += "<p>" + line + "</p>";
      }
    }
    return output;
  }

  /**
   * Callback that responds to this component being added to the DOM tree.
   */
  public mounted() {
    let fileInput = this.$el.querySelector("input[type=file]");
    if (!fileInput || !(fileInput instanceof HTMLElement)) return;
    fileInput.onchange = this.onFileSelected;
  }

  /**
   * Shows the file select dialog.
   */
  public showDialog() {
    let fileInput = this.$el.querySelector("input[type=file]");
    if (!fileInput || !(fileInput instanceof HTMLElement)) return;
    fileInput.click();
  }

  /**
   * Callback that responds to a user clicking the change profile picture button.
   * @param event The object that enables interaction with the event.
   */
  private onClickChangeProfilePicture(event: Event) {
    event.preventDefault();
    this.showDialog();
  }

  /**
   * Callback that responds to the file being selected.
   * @param event The object that enables interaction with the event.
   */
  private onFileSelected(event: Event) {
    event.preventDefault();

    // Find the file input.
    let fileInput = this.$el.querySelector("input[type=file]");
    if (
      !fileInput ||
      !(fileInput instanceof HTMLInputElement) ||
      !fileInput.files
    )
      throw new Error("Could not obtain uploaded file.");
    const file = fileInput.files[0];
    if (typeof FileReader !== "function")
      throw new Error("The browser does not support file reading.");

    // Read the file and update the cropper.
    const reader = new FileReader();
    reader.onload = event => {
      let target: FileReader | null =
        "target" in event && event.target instanceof FileReader
          ? event.target
          : null;
      if (!target || !target.result || target.result instanceof ArrayBuffer)
        throw new Error("Invalid file.");
      let fileInput = this.$el.querySelector("input[type=file]");
      if (!fileInput || !(fileInput instanceof HTMLInputElement))
        throw new Error("Could not obtain uploaded file.");
      fileInput.value = "";
      this.editImageSrc_ = target.result;
      let cropper = this.$refs.cropper as any;
      cropper.replace(target.result);
      this.showModal_ = true;
    };
    reader.readAsDataURL(file);
  }

  /**
   * Callback that responds to the user accepting the cropped image.
   * @param event The object that enables interaction with the event.
   */
  private onClickAcceptImage(event: Event) {
    let cropper = this.$refs.cropper as any;
    let data = cropper.getCroppedCanvas().toDataURL("image/jpeg");
    this.croppedImageSrc_ = data;
    cropper.getCroppedCanvas().toBlob(
      (blob: Blob) => {
        let reader = new FileReader();
        reader.onload = (event: Event) => {
          // Queue the lines from the file.
          let target: FileReader | null =
            "target" in event && event.target instanceof FileReader
              ? event.target
              : null;
          if (!target || !target.result || typeof target.result === "string")
            return; // TODO: Handle error
          EntityServices.uploadProfileImage(this.entity.id, target.result);
        };
        reader.readAsArrayBuffer(blob);
      },
      "image/jpeg",
      0.95
    ); // TODO: We need to prevent navigation while uploading.
  }

  /**
   * Callback that responds to a user clicking the edit profile button.
   * @param event The object that enables interaction with the event.
   */
  private onClickEditProfile(event: Event) {
    this.editEntity_ = Object.assign({}, this.entity);
    this.editMode_ = true;
  }

  /**
   * Callback that responds to a user clicking the edit profile button.
   * @param event The object that enables interaction with the event.
   */
  private onClickSaveEditProfile(event: Event) {
    this.$emit("update:entity", this.editEntity_);
    if (this.editEntity_ === null) return;
    EntityServices.updateEntity(this.editEntity_)
      .then(result => {
        // TODO: Handle error and success.
      })
      .catch(error => {
        console.error(error);
      });
    this.editMode_ = false;
  }

  /**
   * Callback that responds to a user clicking the edit profile button.
   * @param event The object that enables interaction with the event.
   */
  private onClickCancelEditProfile(event: Event) {
    this.editMode_ = false;
  }
}
