















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import SearchResults from "@/components/search/SearchResults.vue";
import SearchFilters from "@/components/shared/SearchFilters.vue";
import EntityServices from "../services/EntityServices";
import { IEntityInfo } from "../types";
import { Route } from "vue-router";
import SearchServices from "../services/SearchServices";

/**
 * Which properties can be ordered.
 */
export enum SearchOrder {
  Name
}

/**
 * Renders the search page.
 */
@Component({ components: { SearchResults, SearchFilters } })
export default class Search extends Vue {
  /**
   * The search terms the user entered.
   */
  @Prop({ default: "" })
  public query!: string;

  /**
   * The fields to query.
   */
  @Prop({ default: () => [] })
  public fields!: string[];

  /**
   * The number of entities to query.
   */
  @Prop({ default: 10 })
  public count!: number;

  /**
   * The offset in to the query to begin returning results.
   */
  @Prop({ default: 0 })
  public offset!: number;

  /**
   * How to order the results.
   */
  @Prop({ default: SearchOrder.Name })
  public orderBy!: SearchOrder;

  // The data
  private people_: IEntityInfo[] | null = null;
  private organizations_: IEntityInfo[] | null = null;
  private findingPeople_: boolean = false;
  private findingOrganizations_: boolean = false;
  private hasMorePeople_: boolean = false;
  private hasMoreOrganizations_: boolean = false;
  private offsetPeople_: number = 0;
  private offsetOrganizations_: number = 0;

  /**
   * Callback that responds to this component being added to the DOM.
   */
  public mounted() {
    this.offsetPeople_ = this.offset;
    this.offsetOrganizations_ = this.offset;
    if (this.query.trim() === "") {
      this.findingPeople_ = false;
      this.findingOrganizations_ = false;
    } else this.makeQuery(this.query, this.fields, this.offset, this.count);
  }

  /**
   * Queries for users or organizations based on the properties of this view.
   * @param query The query to make.
   * @param fields The fields being queried.
   * @param offset The index of the first result.
   * @param count The number of results to return.
   */
  private makeQuery(
    query: string,
    fields: string[],
    offset: number,
    count: number
  ) {
    this.makePeopleQuery(query, fields, offset, count);
    this.makeOrganizationsQuery(query, fields, offset, count);
  }

  /**
   * Queries for people based on the properties of this view.
   * @param query The query to make.
   * @param fields The fields being queried.
   * @param offset The index of the first result.
   * @param count The number of results to return.
   */
  private makePeopleQuery(
    query: string,
    fields: string[],
    offset: number,
    count: number
  ) {
    this.findingPeople_ = true;

    // Make the query.
    SearchServices.searchEntities("person", query, fields, offset, count).then(
      result => {
        this.offsetPeople_ += this.count;
        if (this.people_ === null) this.people_ = [];
        this.people_ = this.people_.concat(result.data);
        this.findingPeople_ = false;
        this.hasMorePeople_ = result.data.length === this.count;
      }
    );
    // TODO: Handle error
  }

  /**
   * Queries for organizations based on the properties of this view.
   * @param query The query to make.
   * @param fields The fields being queried.
   * @param offset The index of the first result.
   * @param count The number of results to return.
   */
  private makeOrganizationsQuery(
    query: string,
    fields: string[],
    offset: number,
    count: number
  ) {
    this.findingOrganizations_ = true;

    // Make the query.
    SearchServices.searchEntities(
      "organization",
      query,
      fields,
      offset,
      count
    ).then(result => {
      this.offsetOrganizations_ += this.count;
      if (this.organizations_ === null) this.organizations_ = [];
      this.organizations_ = this.organizations_.concat(result.data);
      this.findingOrganizations_ = false;
      this.hasMoreOrganizations_ = result.data.length === this.count;
    });
    // TODO: Handle error
  }

  /**
   * Callback that responds to the route being changed.
   * @param newValue The new route.
   */
  @Watch("$route")
  private onRouteChanged(newValue: Route) {
    if (newValue.name !== "search") return;
    let query: string = "";
    let fields: string[] = [];
    let offset: number = 0;
    let count: number = 10;
    if ("query" in newValue.query) query = newValue.query.query as string;
    if ("fields" in newValue.query)
      fields = (newValue.query.fields as string).split(",");
    if ("offset" in newValue.query)
      offset = parseInt(newValue.query.offset as string, 10);
    if ("count" in newValue.query)
      count = parseInt(newValue.query.count as string, 10);
    this.$emit("update:query", query);
    this.$emit("update:fields", fields);
    this.$emit("update:offset", offset);
    this.$emit("update:count", count);
    this.organizations_ = null;
    this.people_ = null;
    this.offsetPeople_ = this.offset;
    this.offsetOrganizations_ = this.offset;
    this.makeQuery(query, fields, offset, count);
  }

  /**
   * Callback that responds to the user clicking on a load more button.
   * @param event The object that enables interaction with the event.
   * @param section The section to load more from.
   */
  private onClickLoadMore(event: Event, section: string) {
    event.preventDefault();
    switch (section) {
      case "person":
        this.makePeopleQuery(
          this.query,
          this.fields,
          this.offsetPeople_,
          this.count
        );
        break;
      case "organization":
        this.makeOrganizationsQuery(
          this.query,
          this.fields,
          this.offsetOrganizations_,
          this.count
        );
        break;
    }
  }
}
