















import EntitySummary from "@/components/shared/EntitySummary.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IEntityInfo } from "../../types";

/**
 * Responsible for rendering a listing of entities.
 */
@Component({ components: { EntitySummary } })
export default class EntityListing extends Vue {
  /**
   * The list of known entities.
   */
  @Prop({ default: () => [] })
  public entities!: IEntityInfo[];

  /**
   * Returns the source for the profile image.
   * @param entity The entity to get the image for.
   * @return The source image.
   */
  public getProfileImageSrc(entity: IEntityInfo): string {
    if (entity.imgSrc === undefined || entity.imgSrc === "")
      return this.$store.getters.imageSrc("profile-placeholder-member.svg");
    else return this.$store.getters.profileSrc(entity.imgSrc);
  }

  /**
   * Returns the link to an entities profile.
   * @param entity The entity to get the href for.
   * @return The link;
   */
  public getHref(entity: IEntityInfo): string {
    return entity.id === this.$store.state.currentEntity.id
      ? "/profile/"
      : `/profile/${entity.id}`;
  }

  /**
   * Returns the name for the given entity.
   * @param entity The entity to get the name of.
   * @return The name.
   */
  public getName(entity: IEntityInfo): string {
    return entity.type === "person"
      ? `${entity.firstName} ${entity.lastName}`
      : entity.name;
  }
}
