











import { Component, Prop, Vue } from "vue-property-decorator";
import { IRgba } from "../../types";

/**
 * Responsible for displaying a loading indicator.
 */
@Component
export default class LoadingIndicator extends Vue {
  /**
   * The size of the indicator in pixels.
   */
  @Prop({ default: 80 })
  public size!: number;

  /**
   * The color of the indicator.
   */
  @Prop({
    default: () => {
      return { r: 255, g: 160, b: 0, a: 1.0 };
    }
  })
  public color!: IRgba;

  /**
   * The size of the stroke.
   */
  @Prop({ default: 4 })
  public stroke!: number;

  /**
   * Returns the style needed to render the size of the indicator.
   * @return The style.
   */
  public get sizeStyle(): string {
    return `min-height: ${this.size + 16}px; min-width: ${this.size + 16}px;`;
  }

  /**
   * Returns the style needed to render the color of the indicator.
   * @return The style.
   */
  public get colorStyle(): string {
    let rgba = `rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, ${this.color.a})`;
    return `border: ${this.stroke}px solid ${rgba}; border-color: ${rgba} transparent transparent transparent;width: ${this.size}px; height: ${this.size}px;`;
  }
}
