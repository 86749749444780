









import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import MainNavigation from "@/components/app/MainNavigation.vue";
import Notifications from "@/components/app/Notifications.vue";
import LoadingIndicator from "@/components/shared/LoadingIndicator.vue";
import { IEntityInfo } from "./types";
import EntityServices from "./services/EntityServices";
import AuthenticateServices from "./services/AuthenticateServices";

/**
 * The component that defines the behaviour of the application.
 */
@Component({ components: { MainNavigation, Notifications, LoadingIndicator } })
export default class App extends Vue {
  private hasData_: boolean = false;
  private hasError_: boolean = false;
  private hasFatalError_: boolean = false;

  /**
   * Returns the class name for this view.
   */
  public get className(): string {
    let name = "";
    let delim = "";
    if (this.hasData_ || this.hasFatalError_) {
      name += "loaded";
      delim = " ";
    } else {
      name += "loading";
      delim = " ";
    }
    if (this.hasError_) {
      name += delim + "error";
      delim = " ";
    }
    if (this.hasFatalError_) {
      name += delim + "fatal";
      delim = " ";
    }
    return name;
  }

  /**
   * Callback that responds to the app being created.
   */
  public created() {
    this.authenticate();
  }

  /**
   * Used to authenticate the application.
   */
  private authenticate() {
    AuthenticateServices.authenticate()
      .then(result => {
        EntityServices.getCurrentEntity()
          .then(result => {
            this.$store.state.currentEntity = result.data;
            this.hasData_ = true;
          })
          .catch(error => {
            this.hasFatalError_ = true;
            this.$router.replace({
              name: "error",
              params: { code: "500", refresh: "true" }
            });
          });
      })
      .catch(error => {
        this.hasFatalError_ = true;
        this.$router.replace({
          name: "error",
          params: { code: "500", refresh: "true" }
        });
      });
  }

  /**
   * Callback that responds to a route being chnaged.
   */
  @Watch("$route", { immediate: true })
  private onRouteChanged(newRoute: any, oldRoute: any) {
    this.hasError_ = newRoute.name === "error" ? true : false;
    if (
      oldRoute !== undefined &&
      oldRoute.name === "error" &&
      newRoute.name !== "error"
    ) {
      this.hasError_ = false;
      this.hasData_ = false;
      this.hasFatalError_ = false;
      this.authenticate();
    }
  }
}
