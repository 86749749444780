


















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Button from "@/components/shared/Button.vue";

/**
 * Renders the profile page.
 */
@Component({ components: { Button } })
export default class Error extends Vue {
  /**
   * The error code.
   */
  @Prop({ default: null })
  public code!: number;

  /**
   * A flag that indicates if refresh is the only option.
   */
  @Prop({ default: false })
  public refresh!: boolean;

  /**
   * A description of what happened.
   */
  public description: string = "";

  /**
   * A description of how to resolve the issue.
   */
  public resolution: string = "";

  /**
   * The location of the image to display.
   */
  public get imageSrc(): string {
    return this.$store.getters.imageSrc("notification-error.svg");
  }

  /**
   * Callback that responds to the error code being changed.
   * @param newValue The new value for the error code.
   */
  @Watch("code", { immediate: true })
  private onCodeChanged(newValue: number | null) {
    if (newValue === null) newValue = 500;
    switch (newValue) {
      case 404:
        this.description = "Oops, sorry!  We can't find that page.";
        this.resolution =
          "Try using the buttons below to try the page again or go home.";
        break;
      case 403:
        this.description = "Looks like you've taken a wrong turn.";
        this.resolution = "This page can only be accessed by authorized users.";
        break;
      case 503:
        this.description = "Looks like we're in a bit of a traffic jam.";
        this.resolution = "Our servers seem to be overloaded.";
        break;
      case 504:
        this.description = "Nobody seems to be home.";
        this.resolution =
          "Our servers failed to communicate in a timly manner.";
        break;
      default:
        this.description = "Something strange has happened...";
        this.resolution = "Sorry for the inconvienince, we’re working on it.";
        break;
    }
  }

  /**
   * Callback that responds to a user clicking the go back button.
   * @param event The object that enables interaction with the event.
   */
  private onClickGoBack(event: Event) {
    this.$router.go(-1);
  }

  /**
   * Callback that responds to a user clicking the go home button.
   * @param event The object that enables interaction with the event.
   */
  private onClickGoHome(event: Event) {
    this.$router.replace({ name: "home", params: {} });
  }
}
