var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',{staticClass:"search-results",attrs:{"color":{ r: 255, g: 87, b: 87, a: 1.0 },"tabs":_vm.tabs}},[_c('div',{attrs:{"slot":"people"},slot:"people"},[_c('EntityListing',{attrs:{"entities":_vm.people}}),(_vm.findingPeople)?_c('LoadingIndicator'):_vm._e(),(!_vm.findingPeople && _vm.hasMorePeople)?_c('div',{staticClass:"load-more"},[_c('Button',{attrs:{"click":_vm.onClickLoadMorePeople}},[_vm._v("Load More")])],1):_vm._e(),(
        !_vm.findingPeople &&
          !_vm.hasMorePeople &&
          _vm.people !== null &&
          _vm.people.length == 0
      )?_c('div',{staticClass:"no-results"},[_vm._v(" No People Found ")]):_vm._e(),(!_vm.findingPeople && _vm.people === null)?_c('div',{staticClass:"no-results"},[_vm._v(" Enter a search term to begin finding people ")]):_vm._e()],1),_c('div',{attrs:{"slot":"organizations"},slot:"organizations"},[_c('EntityListing',{attrs:{"entities":_vm.organizations}}),(_vm.findingOrganizations)?_c('LoadingIndicator'):_vm._e(),(!_vm.findingOrganizations && _vm.hasMoreOrganizations)?_c('div',{staticClass:"load-more"},[_c('Button',{attrs:{"click":_vm.onClickLoadMoreOrganizations}},[_vm._v("Load More")])],1):_vm._e(),(
        !_vm.findingOrganizations &&
          !_vm.hasMoreOrganizations &&
          _vm.organizations !== null &&
          _vm.organizations.length == 0
      )?_c('div',{staticClass:"no-results"},[_vm._v(" No Organizations Found ")]):_vm._e(),(!_vm.findingOrganizations && _vm.organizations === null)?_c('div',{staticClass:"no-results"},[_vm._v(" Enter a search term to begin finding organizations ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }