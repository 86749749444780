







import { Component, Prop, Vue } from "vue-property-decorator";

/**
 * Responsible for displaying an error.
 */
@Component
export default class ErrorIndicator extends Vue {
  /**
   * The error code to use.
   */
  @Prop({ default: 500 })
  public errorCode!: number;

  /**
   * The location of the image to display.
   */
  public get imageSrc(): string {
    return this.$store.getters.imageSrc("notification-error.svg");
  }
}
