import {
  IApiResponseWithData,
  IConnectionInfo,
  IApiResponse,
  INeo4jUserImportExportData,
  INeo4jOrganizationImportExportData,
  INeo4jConnectionMetaData
} from "@/types";
import Constants from "./Constants";
import ServiceHelper from "./ServiceHelper";

/**
 * The class used to query the back-end api for connections.
 */
export default abstract class ConnectionServices {
  /**
   * Returns a subset of connections involving the specified entityId.
   * @param entityId The id of the entity to get connections for.
   * @param direction A string indicating 'from' or 'to' that determines if connections should originate
   * from the specified entityId or if the conactions should lead to the specified entityId.
   * @param type The type of connection to get.  Valid types include "follow", "team", or "mention".
   * @param offset The first connection to fetch.
   * @param count The number of connections to fetch.
   * @return The connections.
   */
  public static async getConnections(
    entityId: number,
    direction: string,
    type: string,
    offset: number = 0,
    count: number = 10
  ): Promise<IApiResponseWithData<IConnectionInfo[]>> {
    let queryParams = `?entityId=${entityId}&direction=${direction}&type=${type}&offset=${offset}&count=${count}`;
    return ServiceHelper.processResponse(
      ServiceHelper.get(`${Constants.endpoints.connections}${queryParams}`)
    );
  }

  /**
   * Formes a new connection between a known entity and the name of another entity to search for.
   * @param fromEntityId The entity to make a connection from.
   * @param toEntityName The name of the entity to make connections to.
   * @param type The type of connection to get.  Valid types include "follow", "team", or "mention".
   * @param filter The filter to apply when searching for the to entity.
   * @param metaData The meta to attach to this connection.
   * @return A response indicating success or failure.
   */
  public static async makeConnection(
    fromEntityId: number,
    toEntityName: string,
    type: string,
    filter: string = "any",
    metaData: object
  ): Promise<IApiResponse> {
    let data = {
      fromEntityId: fromEntityId,
      toEntityName: toEntityName,
      type: type,
      filter: filter,
      metaData: metaData
    };
    return ServiceHelper.processResponse(
      ServiceHelper.post(Constants.endpoints.makeConnection, data)
    );
  }

  /**
   * Sends user information for import to the database.
   * @param from The source of the connection.
   * @param to The source of the connection.
   * @param metaData The data to import.
   * @param fromType The from entity type.
   * @param toType The to entity type.
   * @param connectionType The type of connection.
   * @return The response.
   */
  public static async importConnection(
    from: INeo4jUserImportExportData,
    to: INeo4jOrganizationImportExportData, 
    metaData: INeo4jConnectionMetaData,
    fromType: string,
    toType: string,
    connectionType: string
  ): Promise<IApiResponse> {
    return ServiceHelper.processResponse(
      ServiceHelper.post(Constants.endpoints.importConnection, {
        from: from,
        to: to,
        data: metaData,
        fromType: fromType,
        toType: toType,
        connectionType: connectionType
      })
    );
  }

  /**
   * Creates a bidirectional connection.
   * @param connectionId The connection to verify.
   * @return A response indicating success or failure.
   */
  public static async verifyConnection(
    connectionId: number
  ): Promise<IApiResponse> {
    let data = {
      connectionId: connectionId
    };
    return ServiceHelper.processResponse(
      ServiceHelper.post(Constants.endpoints.verifyConnection, data)
    );
  }

  /**
   * Updates an existing connection.
   * @param id The id of the connection being updated.
   * @param metaData The meta to attach to this connection.
   * @return A response indicating success or failure.
   */
  public static async updateConnection(
    id: number,
    metaData: object
  ): Promise<IApiResponse> {
    let data = {
      id: id,
      metaData: metaData
    };
    return ServiceHelper.processResponse(
      ServiceHelper.post(Constants.endpoints.updateConnection, data)
    );
  }

  /**
   * Removes an existing connection.
   * @param id The id of the connection being removed.
   * @return A response indicating success or failure.
   */
  public static async removeConnection(id: number): Promise<IApiResponse> {
    let data = {
      id: id
    };
    return ServiceHelper.processResponse(
      ServiceHelper.post(Constants.endpoints.removeConnection, data)
    );
  }
}
