



























import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "../shared/Button.vue";
import MessageServices from "../../services/MessageServices";
import { IMessage, IApiResponseWithData } from "../../types";
import ServiceHelper from "../../services/ServiceHelper";
import { AxiosResponse } from "axios";

/**
 * Responsible for handling the notifications
 */
@Component({ components: { Button } })
export default class Notifications extends Vue {
  // The messages being displayed.
  private messages_: IMessage[] = [];

  /**
   * Callback that responds to the component being added to the DOM.
   */
  public mounted() {
    MessageServices.getMessages()
      .then((result: IApiResponseWithData<IMessage[]>) => {
        for (let message of result.data) {
          message.enabled = true;
        }
        this.messages_ = result.data;
      })
      .catch(error => {
        // TODO: Handle error.
      });
  }

  /**
   * Converts a notification to a url.
   * @param level The level to get the url for.
   * @return The url.
   */
  public getIconSrc(level: string): string {
    switch (level) {
      case "warning":
        return this.$store.getters.imageSrc("notification-warning.svg");
      case "error":
        return this.$store.getters.imageSrc("notification-error.svg");
      case "information":
        return this.$store.getters.imageSrc("notification-information.svg");
      default:
        return this.$store.getters.imageSrc("notification-information.svg");
    }
  }

  /**
   * Converts a notification to an alternative text.
   * @param level The level to get the url for.
   * @return The url.
   */
  public getIconAlt(level: string): string {
    switch (level) {
      case "warning":
        return "warning icon";
      case "error":
        return "error icon";
      case "information":
        return "information icon";
      default:
        return "information icon";
    }
  }

  /**
   * Callback that responds to a user clicking a call to action button for a message.
   * @param event The object that enables interaction with the event.
   * @param message The message that was clicked on.
   */
  private onClickMessage(event: Event, message: IMessage) {
    // Prevent user interaction.
    event.preventDefault();
    message.enabled = false;

    // Make the request.
    let data: any | null = null;
    let dataString = "";
    let promise: Promise<AxiosResponse<any>> | null = null;
    switch (message.method) {
      case "REDIRECT":
        // TODO;
        console.error("Not Implemented");
        break;
      case "POST":
        if (message.data !== "") data = JSON.parse(message.data);
        promise = ServiceHelper.post(message.action, data);
        break;
      case "GET":
        if (message.data !== "") {
          data = JSON.parse(message.data);
          dataString =
            "?" +
            Object.keys(data)
              .map(key => key + "=" + data[key])
              .join("&");
        }
        promise = ServiceHelper.get(message.action + dataString);
        break;
    }

    // Process a response if needed.
    if (promise === null) return;
    ServiceHelper.processResponse(promise)
      .then(response => {
        // TODO: If there is a message, create an info message in place of this one that displays the message.
        for (let i = 0; i < this.messages_.length; i++) {
          if (this.messages_[i] === message) {
            this.messages_.splice(i, 1);
            break;
          }
        }
      })
      .catch(error => {
        message.enabled = true;
        // TODO: something about the error.
      });
  }
}
