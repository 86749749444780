






























































import LoadingIndicator from "@/components/shared/LoadingIndicator.vue";
import ConnectionsListing from "@/components/profile/ConnectionListing.vue";
import Button from "@/components/shared/Button.vue";
import SearchTextBox from "@/components/shared/SearchTextBox.vue";
import ConnectionServices from "../../services/ConnectionServices";
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  IEntityInfo,
  IConnectionInfo,
  IConnectionMetaData,
  IAction,
  ITag
} from "../../types";

/**
 * Describes a function that gets the value of a single piece of meta data.
 */
export interface IGetMetaDatumHandler {
  (data: IConnectionMetaData): any;
}

/**
 * Describes a function that responds to a mode change event.
 */
export interface IModeChangedHandler {
  (event?: Event, isEditMode?: boolean, action?: string): void;
}

/**
 * Describes an object that collections information about a single piece of meta data.
 */
export interface IMetaDatumInfo {
  /**
   * The name of the property.
   */
  propertyName: string;
  /**
   * The method used to get the value from this datum.
   */
  getter: IGetMetaDatumHandler;
  /**
   * The type of field to use.
   */
  field: string;
  /**
   * The label to use when updating.
   */
  label: string;
  /**
   * The number of columns to span.
   */
  colspan: number;
}

/**
 * Describes an object that collections parameters needed to construct a connection pane.
 */
export interface IConnectionPaneParams {
  /**
   * The capitalized name of this pane.
   */
  name: string;
  /**
   * The capitalized and pluralized name of this pane.
   */
  plural: string;
  /**
   * The type of connection being maintained.
   */
  type: string;
  /**
   * The meta data.
   */
  metaDataInfo: IMetaDatumInfo[];
  /**
   * The filter to apply when searching for connections.
   */
  filter: string;
  /**
   * Indicates if the entity must exist before making a connection.
   */
  entityMustExist: boolean;
  /**
   * Indicates if incoming connections should be included.
   */
  includeIncoming: boolean;
  /**
   * Indicates if outgoing connections should be included.
   */
  includeOutgoing: boolean;
  /**
   * Which direction to prever if there are conflicts.
   */
  conflictResolution: string;
}

/**
 * Responsible for rendering a "connection pane" widget.
 */
@Component({
  components: {
    ConnectionsListing,
    LoadingIndicator,
    Button,
    SearchTextBox
  }
})
export default class ConnectionPane extends Vue {
  /**
   * The entity to render information for.
   */
  @Prop({ default: null })
  public entity!: IEntityInfo;

  /**
   * Callback that responds to a mode change.
   */
  @Prop({ default: null })
  public modeChange!: IModeChangedHandler;

  /**
   * A flag that indicates if the current user can make edits to this profile.
   */
  @Prop({ default: false })
  public authorized!: boolean;

  // The known connections.
  private connections_: IConnectionInfo[] = [];

  // A flag that indicates if the data has loaded.
  private hasData_: boolean = false;

  // A flag that indicates if we are in edit mode.
  private editMode_: boolean = false;
  private editConnection_: IConnectionInfo | null = null;
  private editName_: string = "";

  // The parameters required to run this pane.
  protected parameters_?: IConnectionPaneParams;

  /**
   * Returns the label used for the search box.
   * @return The label.
   */
  public get searchBoxLabel(): string {
    if (!this.parameters_) throw new Error("The parameters may not be null");
    switch (this.parameters_.filter) {
      case "person":
        return "Person *";
      case "organization":
        return "Organization *";
      case "person-or-email":
        return "Person or Email *";
      case "any":
      default:
        // TODO: Log error if default
        return "Person or Organization *";
    }
  }

  /**
   * Returns the filter to use within the search box.
   * @return The filter.
   */
  public get searchBoxFilter(): string {
    if (!this.parameters_) throw new Error("The parameters may not be null");
    switch (this.parameters_.filter) {
      case "person":
        return "person";
      case "organization":
        return "organization";
      case "person-or-email":
        return "person";
      case "any":
      default:
        return "any";
    }
  }

  /**
   * Returns the text for the placeholder in the search box.
   * @return The text.
   */
  public get searchBoxPlaceholder(): string {
    if (!this.parameters_) throw new Error("The parameters may not be null");
    let prefix: string = this.parameters_.entityMustExist
      ? "Search for"
      : "Search for or create new";
    switch (this.parameters_.filter) {
      case "person":
        return `${prefix} person`;
      case "organization":
        return `${prefix} organization`;
      case "person-or-email":
        return "Search for person or invite using email";
      case "any":
      default:
        // TODO: Log error if default
        return `${prefix} person or organization`;
    }
  }

  /**
   * Returns the text for an empty set of connections.
   * @return The text.
   */
  public get emptyText(): string {
    if (!this.parameters_) throw new Error("The parameters may not be null");
    if (this.$store.state.currentEntity.id === this.entity.id)
      return `You do not have any ${this.parameters_.plural.toLowerCase()} yet.`;
    return this.entity.type === "person"
      ? `${
          this.entity.firstName
        } does not have any ${this.parameters_.plural.toLowerCase()} yet.`
      : `${
          this.entity.name
        } does not have any ${this.parameters_.plural.toLowerCase()} yet.`;
  }

  /**
   * Callback that responds to the component becoming part of the DOM tree.
   */
  public mounted() {
    this.$parent.$on("contextButtonClicked", this.onContextButtonClicked);
    this.$parent.$on("tabChanged", this.onTabChanged);

    // Populate the connections.
    this.refreshConnections();
  }

  /**
   * Indicates if there is data.
   * @return True if there is data, false otherwise.
   */
  protected get hasData(): boolean {
    return this.hasData_;
  }

  /**
   * Indicates if there is data.
   * @param flag The new value for the has data flag.
   */
  protected set hasData(flag: boolean) {
    this.hasData_ = flag;
  }

  /**
   * Returns the actions for the given connection.
   * @param connection The connection to get the actions for.
   * @return The actions for the given connection.
   */
  protected getActions(connection: IConnectionInfo): IAction[] {
    return [];
  }

  /**
   * Returns the tags for the given connection.
   * @param connection The connection to get the tags for.
   * @return The tags for the given connection.
   */
  protected getTags(connection: IConnectionInfo): ITag[] {
    return [];
  }

  /**
   * Begins editing a connection.
   * @param connection The connection to edit.
   */
  protected editConnection(connection: IConnectionInfo): void {
    this.editConnection_ = connection;
    console.log("HERE");
    this.editName_ = this.entity.id === this.editConnection_.from.id 
      ? this.editConnection_.to.name
      : this.editConnection_.from.name;
    this.editMode_ = true;
  }

  /**
   * Removes the specified connection.
   * @param connection The connection to remove.
   */
  protected removeConnection(connection: IConnectionInfo): void {
    if (connection.bidirectional) this.hasData_ = false;
    ConnectionServices.removeConnection(connection.id)
      .then(results => {
        if (connection.bidirectional) this.refreshConnections();
      })
      .catch(error => {
        console.log(error);
      });
    if (!connection.bidirectional) {
      for (let i = 0; i < this.connections_.length; i++) {
        if (this.connections_[i].id === connection.id) {
          this.connections_.splice(i, 1);
          break;
        }
      }
    }
  }

  /**
   * Verifies an existing connection.
   * @param connection The connection to verify.
   */
  protected verifyConnection(connection: IConnectionInfo) {
    this.hasData_ = false;
    ConnectionServices.verifyConnection(connection.id)
      .then(results => {
        this.refreshConnections();
      })
      .catch(error => {
        console.log(error);
      });
  }

  /**
   * Makes a new connection using the current edited information.
   * @param connection The connection to make.
   */
  protected makeConnection(connection: IConnectionInfo) {
    // TODO: We need to make sure the to.name adheres to this.entityMustExist
    if (!this.parameters_) throw new Error("The parameters may not be null");
    let metaData: Record<string, any> = {};
    for (let datumInfo of this.parameters_.metaDataInfo) {
      metaData[datumInfo.propertyName] = datumInfo.getter(connection.metaData);
    }
    connection.to.name = this.editName_;
    this.editName_ = "";
    ConnectionServices.makeConnection(
      connection.from.id,
      connection.to.name,
      this.parameters_.type,
      this.parameters_.filter,
      metaData
    )
      .then(results => {
        this.refreshConnections();
      })
      .catch(error => {
        console.log(error);
      });
  }

  /**
   * Queries for connections and applies them to the current list.
   */
  protected refreshConnections() {
    console.log('asd');
    if (!this.parameters_) throw new Error("The parameters may not be null");
    // Determine the direction.
    this.connections_ = [];
    let direction = "both";
    if (this.parameters_.includeOutgoing && this.parameters_.includeIncoming)
      direction = "both";
    else if (this.parameters_.includeOutgoing) direction = "from";
    else if (this.parameters_.includeIncoming) direction = "to";
    else {
      this.hasData_ = true;
      return;
    }

    // Make the query.
    ConnectionServices.getConnections(
      this.entity.id,
      direction,
      this.parameters_.type
    )
      .then(results => {
        if (!this.parameters_)
          throw new Error("The parameters may not be null");
        // Determine where to place the results.
        let outgoingConnections = [];
        let incomingConnections = [];
        for (let result of results.data) {
          // If the direction is outgoing, see if we had a previous incoming.
          if (result.to.id === this.entity.id) {
            console.log("New Incoming Connection");
            console.log((result.from.name !== undefined ? result.from.name : result.from.firstName + result.from.lastName) + " => " + (result.to.name !== undefined ? result.to.name : result.to.firstName + result.to.lastName));

            // Try to merge an entity.
            let merge: IConnectionInfo | null = null;
            let index = 0;
            for (let old of incomingConnections) {
              if (old.from.id == this.entity.id && old.to.id == result.from.id) {
                console.log("Found existing outgoing connection");
                console.log((old.from.name !== undefined ? old.from.name : old.from.firstName + old.from.lastName) + " => " + (old.to.name !== undefined ? old.to.name : old.to.firstName + old.to.lastName));
                merge = old;
                break;
              }
              index++;
            }

            // See if we need to merge.
            if (merge === null) outgoingConnections.push(result);
            else {
              if (this.parameters_.conflictResolution === "to") {
                merge.metaData = result.metaData = this.mergeMetaData(
                  result.metaData,
                  merge.metaData
                );
                result.otherConnection = merge;
                merge.otherConnection = result;
                outgoingConnections.push(result);
                incomingConnections.splice(index, 1);
              } else {
                result.metaData = merge.metaData = this.mergeMetaData(
                  merge.metaData,
                  result.metaData
                );
                merge.otherConnection = result;
                result.otherConnection = merge;
              }
            }
          }
          // If the direction is incoming, see if we had a previous outgoing.
          if (result.from.id === this.entity.id) {
            console.log("New Outgoing Connection");
            console.log((result.from.name !== undefined ? result.from.name : result.from.firstName + result.from.lastName) + " => " + (result.to.name !== undefined ? result.to.name : result.to.firstName + result.to.lastName));

            // Try to merge an entity.
            let merge: IConnectionInfo | null = null;
            let index = 0;
            for (let old of outgoingConnections) {
              if (old.to.id == this.entity.id && old.from.id == result.to.id) {
                console.log("Found existing outgoing connection:");
                console.log((old.from.name !== undefined ? old.from.name : old.from.firstName + old.from.lastName) + " => " + (old.to.name !== undefined ? old.to.name : old.to.firstName + old.to.lastName));
                merge = old;
                break;
              }
              index++;
            }

            // See if we need to merge.
            if (merge === null) incomingConnections.push(result);
            else {
              if (this.parameters_.conflictResolution === "from") {
                merge.metaData = result.metaData = this.mergeMetaData(
                  result.metaData,
                  merge.metaData
                );
                result.otherConnection = merge;
                merge.otherConnection = result;
                incomingConnections.push(result);
                outgoingConnections.splice(index, 1);
              } else {
                result.metaData = merge.metaData = this.mergeMetaData(
                  merge.metaData,
                  result.metaData
                );
                merge.otherConnection = result;
                result.otherConnection = merge;
              }
            }
          }
        }

        // Filter the connections.
        let connections = incomingConnections.concat(outgoingConnections);
        for (let connection of connections) {
          connection.actions = this.getActions(connection);
          connection.tags = this.getTags(connection);
        }
        this.connections_ = connections;
        this.hasData_ = true;
      })
      .catch(error => {
        // TODO: Do something with the error.
        console.log(error);
      });
  }

  /**
   * Merges two connection meta data preferring the data from the primary.
   * @param primary The connection meta data to prefer.
   * @param secondary The connection meta data to fill.
   * @return The merged meta data.
   */
  private mergeMetaData(
    primary: IConnectionMetaData,
    secondary: IConnectionMetaData
  ): IConnectionMetaData {
    let merged: IConnectionMetaData = {
      startDate: 0,
      endDate: 0,
      position: "",
      description: "",
      claimId: 0
    };

    if (primary.startDate && primary.startDate !== 0)
      merged.startDate = primary.startDate;
    else if (secondary.startDate && secondary.startDate !== 0)
      merged.startDate = secondary.startDate;

    if (primary.endDate && primary.endDate !== 0)
      merged.endDate = primary.endDate;
    else if (secondary.endDate && secondary.endDate !== 0)
      merged.endDate = secondary.endDate;

    if (primary.position && primary.position !== "")
      merged.position = primary.position;
    else if (secondary.position && secondary.position !== "")
      merged.position = secondary.position;

    if (primary.description && primary.description !== "")
      merged.description = primary.description;
    else if (secondary.description && secondary.description !== "")
      merged.description = secondary.description;

    if (primary.claimId && primary.claimId !== 0)
      merged.claimId = primary.claimId;
    else if (secondary.claimId && secondary.claimId !== 0)
      merged.claimId = secondary.claimId;
    return merged;
  }

  /**
   * Updates a connection using the currently edited information.
   */
  private updateConnection() {
    if (!this.parameters_) throw new Error("The parameters may not be null");
    if (this.editConnection_ === null) return;
    if (this.entity.id === this.editConnection_.from.id) 
      this.editConnection_.to.name = this.editName_;
    else
      this.editConnection_.from.name = this.editName_;
    this.editName_ = "";
    let metaData: Record<string, any> = {};
    for (let datumInfo of this.parameters_.metaDataInfo) {
      metaData[datumInfo.propertyName] = datumInfo.getter(
        this.editConnection_.metaData
      );
    }
    ConnectionServices.updateConnection(this.editConnection_.id, metaData)
      .then(results => {
        this.refreshConnections();
      })
      .catch(error => {
        console.log(error);
      });
  }

  /**
   * Callback that responds to the save button being clicked.
   * @param event The event that enables interaction with the click event.
   */
  private onClickSave(event: Event) {
    if (this.editConnection_ === null) return;
    // See if someone cancelled the save event.
    let newEvent: Event = new Event("modeChange");
    if (this.modeChange !== null) this.modeChange(newEvent, false, "save");
    if (newEvent.defaultPrevented) return;
    if (this.editConnection_.id === 0)
      this.makeConnection(this.editConnection_);
    else this.updateConnection();
    this.hasData_ = false;
    this.editConnection_ = null;
    this.editMode_ = false;
  }

  /**
   * Callback that responds to the save button being clicked.
   * @param event The event that enables interaction with the click event.
   */
  private onClickCancel(event: Event) {
    let newEvent: Event = new Event("modeChange");
    if (this.modeChange !== null) this.modeChange(newEvent, false, "cancel");
    if (newEvent.defaultPrevented) return;
    this.editConnection_ = null;
    this.editMode_ = false;
  }

  /**
   * Callback that responds to the context button in the parent container being clicked.
   * @param target The target of the context event.
   */
  private onTabChanged(target: Vue) {
    this.editConnection_ = null;
    this.editMode_ = false;
  }

  /**
   * Callback that responds to the context button in the parent container being clicked.
   * @param target The target of the context event.
   */
  private onContextButtonClicked(target: Vue) {
    if (!this.parameters_) throw new Error("The parameters may not be null");
    if (target !== this) return;
    let event: Event = new Event("modeChange");
    if (this.modeChange !== null) this.modeChange(event, true, "add");
    if (event.defaultPrevented) return;

    // Create the new dummy connection.
    let metaData: IConnectionMetaData = {
      startDate: new Date().getTime() / 1000,
      endDate: new Date().getTime() / 1000,
      position: "",
      description: "",
      claimId: 0
    };
    let connection = {
      id: 0,
      from: this.entity,
      type: this.parameters_.type,
      bidirectional: false,
      otherConnection: null,
      createdDate: new Date().getTime() / 1000,
      to: {
        name: ""
      } as IEntityInfo,
      metaData: metaData,
      tags: [],
      actions: []
    };
    this.editConnection(connection);
  }

  /**
   * Callback that responds to the user choosing an entity.
   * @param event The object that enables interaction with the event.
   * @param entity The entity that was chosen.
   */
  private onChooseEntity(event: Event, entity: IEntityInfo) {
    event.preventDefault();
    if (this.editConnection_ === null) return;
  }
}
