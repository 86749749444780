











import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IRgba } from "../../types";

/**
 * Wraps children in a bordered container.
 */
@Component
export default class AdminWidget extends Vue {
  /**
   * The color for this container.
   */
  @Prop({
    default: () => {
      return { r: 255, g: 0, b: 255, a: 1.0 };
    }
  })
  public color!: IRgba;

  /**
   * The number of columns this container spans.
   */
  @Prop({ default: 1 })
  public colspan!: number;

  /**
   * The number of rows this container spans.
   */
  @Prop({ default: 1 })
  public rowspan!: number;

  /**
   * Returns the style for the element.
   * @return The style css.
   */
  public get style(): string {
    return `background: rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, ${this.color.a});`;
  }
}
