


















import EntitySummary from "@/components/shared/EntitySummary.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  IConnectionInfo,
  IEntityInfo,
  ITag,
  IAction,
  IRgba
} from "../../types";

/**
 * Describes a function that responds to action handler.
 */
export interface IConnectionActionHandler {
  (event?: Event, connection?: IConnectionInfo, action?: IAction): void;
}

/**
 * Responsible for rendering a listing of entities.
 */
@Component({ components: { EntitySummary } })
export default class ConnectionListing extends Vue {
  /**
   * The list of known entities.
   */
  @Prop({ default: () => [] })
  public connections!: IConnectionInfo[];

  /**
   * The entity that owns this listing.
   */
  @Prop({ default: null })
  public entity!: IEntityInfo;

  /**
   * The callback that responds to an action being taken.
   */
  @Prop({ default: null })
  public action!: IConnectionActionHandler;

  /**
   * Returns the name for the connection.
   * @param connection The connection to get the name for.
   * @return The name.
   */
  public getName(connection: IConnectionInfo): string {
    let entity: IEntityInfo = this.getTargetEntity(connection);
    return entity.type === "person"
      ? `${entity.firstName} ${entity.lastName}`
      : entity.name;
  }

  /**
   * Returns the actions for the given connection.
   * @param connection The connection to get the actions for.
   * @return The actions.
   */
  public getActions(connection: IConnectionInfo): IAction[] {
    return connection.actions ? connection.actions : [];
  }

  /**
   * Returns the tags for the given connection.
   * @param connection The connection to get the name for.
   * @return The tags.
   */
  public getTags(connection: IConnectionInfo): ITag[] {
    return connection.tags ? connection.tags : [];
  }

  /**
   * Returns the name for the connection.
   * @param connection The connection to get the name for.
   * @return The name.
   */
  public getSubtitle(connection: IConnectionInfo): string {
    let entity: IEntityInfo = this.getTargetEntity(connection);
    switch (connection.type) {
      case "team":
        return connection.metaData.position;
      case "media":
        return "";
      case "connection":
        return entity.tagline;
      case "follow":
        return entity.tagline;
      default:
        throw new Error("Unknown type of connection encountered.");
    }
  }

  /**
   * Returns the description for the connection.
   * @param connection The connection to get the description for.
   * @return The description.
   */
  public getDescription(connection: IConnectionInfo): string {
    let entity: IEntityInfo = this.getTargetEntity(connection);
    switch (connection.type) {
      case "team":
        return connection.metaData.description;
      case "media":
        return "";
      case "connection":
        return "";
      case "follow":
        return "";
      default:
        throw new Error("Unknown type of connection encountered.");
    }
  }

  /**
   * Returns the meta data for the connection.
   * @param connection The connection to get the meta data for.
   * @return The meta data.
   */
  public getMetaData(connection: IConnectionInfo): string {
    let entity: IEntityInfo = this.getTargetEntity(connection);
    switch (connection.type) {
      case "team":
        if (connection.metaData.startDate === null) return "";
        else if (connection.metaData.endDate === null) {
          let start = new Date(
            connection.metaData.startDate * 1000
          ).toLocaleString("en-US", { month: "short", year: "numeric" });
          return `${start} - Current`;
        } else if (
          connection.metaData.startDate === null &&
          connection.metaData.endDate === null
        )
          return "";
        else {
          let start = new Date(
            connection.metaData.startDate * 1000
          ).toLocaleString("en-US", { month: "short", year: "numeric" });
          let end = new Date(
            connection.metaData.endDate * 1000
          ).toLocaleString("en-US", { month: "short", year: "numeric" });
          return `${start} - ${end}`;
        }
      case "media":
        return "TODO";
      case "connection":
        return "";
      case "follow":
        return "";
      default:
        throw new Error("Unknown type of connection encountered.");
    }
  }

  /**
   * Returns the source for the image.
   * @param connection The connection to get the image for.
   * @return The source image.
   */
  public getImageSrc(connection: IConnectionInfo): string {
    let entity: IEntityInfo = this.getTargetEntity(connection);
    if (entity.imgSrc === undefined || entity.imgSrc === "")
      return this.$store.getters.imageSrc("profile-placeholder-member.svg");
    else return this.$store.getters.profileSrc(entity.imgSrc);
  }

  /**
   * Returns the alternative text for the image.
   * @param connection The connection to get the alternative text for.
   * @return The alternative text.
   */
  public getImageAlt(connection: IConnectionInfo): string {
    let entity: IEntityInfo = this.getTargetEntity(connection);
    return entity.imgAlt;
  }

  /**
   * Returns the link to an entities profile.
   * @param connection The connection to get the href for.
   * @return The link.
   */
  public getHref(connection: IConnectionInfo): string {
    let entity = this.getTargetEntity(connection);
    return `/profile/${entity.id}`;
  }

  /**
   * Returns the entity that should be displayed.
   * @param connection The connection to get the target for.
   * @return The entity.
   */
  private getTargetEntity(connection: IConnectionInfo): IEntityInfo {
    return this.entity.id === connection.to.id
      ? connection.from
      : connection.to;
  }

  /**
   * Callback that responds to the action being clicked.
   * @param event The object that enables interaction with the event.
   * @param connection The connection the action belongs to.
   * @param action The action that was clicked.
   */
  private onClickAction(
    event: Event,
    connection: IConnectionInfo,
    action: IAction
  ) {
    if (this.action !== null) this.action(event, connection, action);
  }
}
