import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import About from "@/views/Home.vue";
import Profile from "@/views/Profile.vue";
import Search from "@/views/Search.vue";
import Error from "@/views/Error.vue";
import Admin from "@/views/Admin.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: { name: "home" }
  }, // TODO: This should redirect to error 404 however there is a strange bug where the initial page isn't home
  {
    path: "/",
    name: "home",
    component: Home
  },
  {
    path: "/about",
    name: "about",
    component: About
  },
  {
    path: "/profile/:id?",
    name: "profile",
    component: Profile,
    props: true
  },
  {
    path: "/search",
    name: "search",
    component: Search,
    props: (route: any) => {
      let query: string = "";
      let fields: string[] = [];
      let offset: number = 0;
      let count: number = 10;
      if ("query" in route.query) query = route.query.query;
      if ("fields" in route.query) fields = route.query.fields.split(",");
      if ("offset" in route.query) offset = parseInt(route.query.offset, 10);
      if ("count" in route.query) count = parseInt(route.query.count, 10);
      return {
        query: query,
        fields: fields,
        offset: offset,
        count: count
      };
    }
  },
  {
    path: "/admin",
    name: "admin",
    component: Admin
  },
  {
    path: "/error/:code?",
    name: "error",
    component: Error,
    props: (route: any) => {
      let code = parseInt(route.params.code, 10);
      let refresh =
        route.params.refresh === undefined ? "false" : route.params.refresh;
      return {
        code: isNaN(code) ? 500 : code,
        refresh: refresh === "true" ? true : false
      };
    }
  }
];

// Determine the base url for routing.
let windowAsAny: any = window as any;
let baseUrl = process.env.BASE_URL;
if (windowAsAny.VueData) {
  let relativeUrl = windowAsAny.VueData.baseUrl.substring(
    windowAsAny.VueData.siteUrl.length
  );
  baseUrl = relativeUrl;
}

// Construct the router.
const router = new VueRouter({
  mode: "history",
  base: baseUrl,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
}); // TODO: Investigate replacing the base url

export default router;
