






































import Button from "@/components/shared/Button.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IRgba } from "../../types";
import { IClickHandler } from "./Button.vue";

/**
 * Describes a function that responds to a button click.
 */
export interface ITabChangedHandler {
  (event?: Event, newTab?: string | null, oldTab?: string | null): void;
}

/**
 * Wraps children in a bordered container.
 */
@Component({ components: { Button } })
export default class Container extends Vue {
  /**
   * The color for the background of this container.
   */
  @Prop({
    default: () => {
      return { r: 255, g: 255, b: 255, a: 1.0 };
    }
  })
  public colorBackground!: IRgba;

  /**
   * The color for the heading of this container.
   */
  @Prop({
    default: () => {
      return { r: 113, g: 106, b: 106, a: 1.0 };
    }
  })
  public colorHeading!: IRgba;

  /**
   * The color for the heading text.
   */
  @Prop({
    default: () => {
      return { r: 255, g: 255, b: 255, a: 1.0 };
    }
  })
  public colorHeadingText!: IRgba;

  /**
   * The title to display
   */
  @Prop({ default: "" })
  public title!: string;

  /**
   * The number of columns this container spans.
   */
  @Prop({ default: 3 })
  public colspan!: number;

  /**
   * A flag that indicates if the context button is being shown.
   */
  @Prop({ default: false })
  public contextButtonVisible!: boolean;

  /**
   * The text for the context button.
   */
  @Prop({ default: "" })
  public contextButtonText!: string;

  /**
   * Callback that responds to context button being clicked.
   */
  @Prop({ default: null })
  public contextButtonClick!: IClickHandler;

  /**
   * The tabs.
   */
  @Prop({ default: () => [] })
  public tabs!: string[];

  /**
   * A flag that indicates if the border is visible.
   */
  @Prop({ default: false })
  public noBorder!: boolean;

  /**
   * A flag that indicates if padding should be hidden.
   */
  @Prop({ default: false })
  public noPadding!: boolean;

  /**
   * The callback that responds to a tab changing.
   */
  @Prop({ default: null })
  public tabChange!: ITabChangedHandler;

  // The currently active tab.
  private activeTab_: string | null = null;

  /**
   * The class name for this element.
   */
  public get className(): string {
    let tabs = this.tabs.length > 0 ? " has-tabs" : "";
    let noBorder = this.noBorder ? " no-border" : "";
    let noPadding = this.noPadding ? " no-padding" : "";
    return `portal-container${tabs}${noBorder}${noPadding}`;
  }

  /**
   * Returns the currently active tab.
   */
  public get activeTab(): string {
    if (this.activeTab_ === null) return "";
    return this.activeTab_;
  }

  /**
   * Indicates if there are tabs.
   */
  public get hasTabs(): boolean {
    return this.tabs.length > 0;
  }

  /**
   * Returns the style for the heading.
   */
  private get headingStyle(): string {
    let background = `${this.colorHeading.r}, ${this.colorHeading.g}, ${this.colorHeading.b}, ${this.colorHeading.a}`;
    return `background: rgba(${background});`;
  }

  /**
   * Returns the style for the font.
   */
  private get fontStyle(): string {
    let font = `${this.colorHeadingText.r}, ${this.colorHeadingText.g}, ${this.colorHeadingText.b}, ${this.colorHeadingText.a}`;
    return `color: rgba(${font});`;
  }

  /**
   * Returns the style for the heading.
   */
  private get contentStyle(): string {
    let background = `${this.colorBackground.r}, ${this.colorBackground.g}, ${this.colorBackground.b}, ${this.colorBackground.a}`;
    return `background: rgba(${background});`;
  }

  /**
   * Returns the class name for a given tab.
   * @param tab The string to convert.
   * @return The converted tab.
   */
  public getTabClassName(tab: string): string {
    let slug = this.getTabSlug(tab);
    return slug === this.activeTab_
      ? `tab tab-${slug} active`
      : `tab tab-${slug}`;
  }

  /**
   * Returns the class name for a given tab content.
   * @param tab The string to convert.
   * @return The converted tab.
   */
  public getTabContentClassName(tab: string): string {
    let slug = this.getTabSlug(tab);
    return slug === this.activeTab_
      ? `tab-content tab-${slug} active`
      : `tab-content tab-${slug}`;
  }

  /**
   * Returns an HTML safe version of the provided string.
   * @param tab The string to convert.
   * @return The converted tab.
   */
  public getTabSlug(tab: string): string {
    return tab
      .replace(/(?:^|\.?)([A-Z])/g, function(x, y) {
        return "-" + y.toLowerCase();
      })
      .replace(/^-/, "");
  }

  /**
   * Callback that responds to the tabs changing.
   * @param newValue The new value for the tabs.
   */
  @Watch("tabs", { immediate: true })
  private onTabsChanged(newValue: string[]) {
    if (newValue.length === 0) this.onClickTab(null);
    // TODO: Only switch the active tab if the old tab is no longer present.
    else this.onClickTab(newValue[0]);
  }

  /**
   * Callback that responds to the user clicking a tab.
   * @param tab The tab being clicked.
   */
  private onClickTab(tab: string | null) {
    let event: Event = new Event("tabChange");
    let newTab: string | null = tab === null ? null : this.getTabSlug(tab);
    if (this.tabChange !== null) this.tabChange(event, newTab, this.activeTab_);
    if (!event.defaultPrevented) this.activeTab_ = newTab;
  }

  /**
   * Callback that responds to the context button being clicked.
   * @param event The object that enables interaction with the click event.
   */
  private onClickContextButton(event: Event) {
    if (this.contextButtonClick !== null) this.contextButtonClick(event);
  }
}
