import { IApiResponseWithData, IPostInfo } from "@/types";
import Constants from "./Constants";
import ServiceHelper from "./ServiceHelper";

/**
 * The class used to query the back-end api for posts.
 */
export default abstract class PostServices {
  /**
   * Queries for the latest posts.
   * @param offset The first entity to fetch.
   * @param count The number of entities to fetch.
   * @return The entities.
   */
  public static async getLatestPosts(
    offset: number = 0,
    count: number = 10
  ): Promise<IApiResponseWithData<IPostInfo[]>> {
    let queryParams = `?offset=${offset}&count=${count}`;
    return ServiceHelper.processResponse(
      ServiceHelper.get(`${Constants.endpoints.latestPosts}${queryParams}`)
    );
  }
}
