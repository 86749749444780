



















import { Component, Prop, Vue } from "vue-property-decorator";
import AdminWidget from "./AdminWidget.vue";
import ProgressIndicator from "@/components/shared/ProgressIndicator.vue";
import Button from "@/components/shared/Button.vue";
import EntityServices from "../../services/EntityServices";
import {
  IRgba,
  INeo4jUserImportExportData,
  INeo4jOrganizationImportExportData
} from "../../types";

/**
 * Responsible for handling an entity import widget.
 */
@Component({ components: { AdminWidget, ProgressIndicator, Button } })
export default class ImportEntity extends Vue {
  // The queue of file to load.
  private totalEntities_: number = 0;
  private entityQueue_: string[] = [];
  private status_: string | null = null;
  private buttonsEnabled_: boolean = true;

  /**
   * The color for the panel.
   * @return The color.
   */
  public get color(): IRgba {
    return { r: 158, g: 64, b: 117, a: 1.0 };
  }

  /**
   * The color of the info button.
   * @return The color.
   */
  public get infoButtonColor(): IRgba {
    return { r: 62, g: 112, b: 211, a: 1.0 };
  }

  /**
   * Returns the status text for the progress bar.
   * @return The status.
   */
  public get status(): string {
    if (this.status_ !== null) return this.status_;
    return this.totalEntities_ === 0
      ? "Choose a file to upload"
      : `Processed ${this.totalEntities_ - this.entityQueue_.length} of ${
          this.totalEntities_
        }`;
  }

  /**
   * Calculates the percentage of files that have been completed.
   * @return The percentage.
   */
  public get percent(): number {
    return this.totalEntities_ === 0
      ? 0
      : (this.totalEntities_ - this.entityQueue_.length) / this.totalEntities_;
  }

  /**
   * Called when the widget is added to the DOM tree.
   */
  public mounted() {
    let fileInput = this.$el.querySelector("input[type=file]");
    if (!fileInput || !(fileInput instanceof HTMLElement)) return;
    fileInput.onchange = this.onFileSelected;
  }

  /**
   * Processes the next entity in the queue.
   */
  private async processEntities() {
    try {
      let status = "";
      while (this.entityQueue_.length > 0) {
        // Read the entity.
        let entityString = this.entityQueue_[0].replace(/^[\s"]+|[\s"]+$/g, "");
        this.entityQueue_.splice(0, 1);
        let entity = this.fromJson(entityString);

        // Do the import.
        let result = null;
        let retry = 0;
        while (retry < 3) {
          try {
            if (this.isNeo4jUser(entity) || this.isNeo4jOrganization(entity)) {
              result = await EntityServices.importEntity(entity);
              break;
            } else retry++;
          } catch (e) {
            retry++;
          }
        }
        if (retry === 3) {
          status = "Network Error";
          throw new Error("Network Error");
        }
      }
      this.totalEntities_ = 0;
      this.status_ = "Upload complete";
      this.buttonsEnabled_ = true;
    } catch (e) {
      this.totalEntities_ = 0;
      this.status_ = status.length === 0 ? "Failed to upload" : status;
      this.buttonsEnabled_ = true;
    }
  }

  /**
   * Converts a string to an object.
   * @param jsonString The string to convert.
   * @return The object.
   */
  private fromJson(jsonString: string) {
    let mode = 0;
    let key = "";
    let isString = false;
    let value = "";
    let jsonObjectString = "";
    let delim = "";
    let ended = false;
    for (let i = 0; i < jsonString.length; i++) {
      let char = jsonString.charAt(i);
      switch (mode) {
        case 0: // Looking for key.
          if (
            char === '"' &&
            i + 1 < jsonString.length &&
            jsonString.charAt(i + 1) === '"'
          ) {
            i++;
            mode = 1;
          }
          break;
        case 1: // Reading key.
          if (char === '"') mode = 2;
          else key += char;
          break;
        case 2: // Looking for seperator.
          if (char === ":") {
            if (i + 1 < jsonString.length && jsonString.charAt(i + 1) === '"') {
              i += 2;
              isString = true;
            } else isString = false;
            mode = 3;
          }
          break;
        case 3: // Looking for value.
          ended = false;
          if (isString) {
            if (
              char === '"' &&
              i + 2 < jsonString.length &&
              jsonString.charAt(i + 1) === '"' &&
              (jsonString.charAt(i + 2) === "," ||
                jsonString.charAt(i + 2) === "}")
            ) {
              i += 2;
              ended = true;
            }
          } else {
            if (char === "," || char === "}") ended = true;
          }
          if (ended) {
            let unicodeRegex = new RegExp(
              `[${String.fromCharCode(31)}|${String.fromCharCode(
                30
              )}|${String.fromCharCode(11)}]`,
              "gm"
            );
            value = value
              .replace(unicodeRegex, "")
              .replace(/\\/gm, "\\\\")
              .replace(/""/gm, '"')
              .replace(/\n/gm, "\\n")
              .replace(/\r/gm, "\\r")
              .replace(/\t/gm, "\\t")
              .replace(/"/gm, '\\"');
            jsonObjectString += isString
              ? `${delim}"${key}":"${value}"`
              : `${delim}"${key}":${value}`;
            delim = ",";
            mode = 0;
            key = "";
            value = "";
          } else value += char;
          break;
      }
    }
    let encoded = this.encodeUnicode(jsonObjectString);
    return JSON.parse(`{${encoded}}`);
  }

  /**
   * Encodes a unicode string.
   * @param input The input to encode.
   * @return The encoded string.
   */
  private encodeUnicode(input: string): string {
    return input.replace(/[\s\S]/g, function(character) {
      let escape = character.charCodeAt(0).toString(16);
      let needsEscape = escape.length > 2;
      let encoded = "\\u" + ("0000" + escape).slice(-4);
      return needsEscape ? encoded : character;
    });
  }

  /**
   * Callback that responds to a user clicking the edit profile button.
   * @param event The object that enables interaction with the event.
   */
  private onClickUploadCSV(event: Event) {
    event.preventDefault();
    let fileInput = this.$el.querySelector("input[type=file]");
    if (!fileInput || !(fileInput instanceof HTMLElement)) return;
    fileInput.click();
  }

  /**
   * Callback that responds to the file being selected.
   * @param event The object that enables interaction with the event.
   */
  private onFileSelected(event: Event) {
    event.preventDefault();
    let fileInput = this.$el.querySelector("input[type=file]");
    if (
      !fileInput ||
      !(fileInput instanceof HTMLInputElement) ||
      !fileInput.files
    ) {
      this.status_ = "Error processing file";
      this.buttonsEnabled_ = true;
      return;
    }

    // If there is a file, process it.
    this.totalEntities_ = 0;
    this.buttonsEnabled_ = false;

    // TODO: Prevent multiple files.
    if (fileInput.files.length === 1) {
      this.status_ = "Processing file ...";
      let file = fileInput.files[0];
      let reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = evt => {
        // Queue the lines from the file.
        let target: FileReader | null =
          "target" in evt && evt.target instanceof FileReader
            ? evt.target
            : null;
        if (!target || !target.result || target.result instanceof ArrayBuffer) {
          this.status_ = "Error processing file";
          this.buttonsEnabled_ = true;
          return;
        }
        let lines = target.result.match(/"?({[^}]*?})"?/g);
        if (lines === null || lines.length === 0) {
          this.status_ = "The file contains no data";
          this.buttonsEnabled_ = true;
          return;
        }
        this.entityQueue_ = lines;
        this.totalEntities_ = this.entityQueue_.length;
        this.status_ = null;
        this.processEntities();
      };
      reader.onerror = evt => {
        this.status_ = "Error processing file";
        this.buttonsEnabled_ = true;
      };
    } else {
      this.status_ = "Please select only 1 file";
      this.buttonsEnabled_ = true;
    }
    fileInput.value = "";
  }

  /**
   * Checks to see if the supplied object is a neo4j user.
   * @param object The object to check.
   * @return The converted object.
   */
  private isNeo4jUser(object: any): object is INeo4jUserImportExportData {
    return (
      "created_at" in object &&
      "uuid" in object &&
      "first_name" in object &&
      "email" in object &&
      "last_name" in object
    );
  }

  /**
   * Checks to see if the supplied object is a neo4j organization.
   * @param object The object to check.
   * @return The converted object.
   */
  private isNeo4jOrganization(
    object: any
  ): object is INeo4jOrganizationImportExportData {
    return "uuid" in object && "name" in object;
  }
}
