

















































import Container from "@/components/shared/Container.vue";
import Experience from "@/components/profile/Experience.vue";
import Connections from "@/components/profile/Connections.vue";
import Follows from "@/components/profile/Follows.vue";
import Team from "@/components/profile/Team.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IEntityInfo, IRgba } from "../../types";

/**
 * Responsible for rendering a "connections" widget.
 */
@Component({
  components: {
    Container,
    Experience,
    Connections,
    Follows,
    Team
  }
})
export default class ConnectionsContainer extends Vue {
  /**
   * The entity to render information for.
   */
  @Prop({ default: null })
  public entity!: IEntityInfo;

  /**
   * A flag that indicates if the current user can make edits to this profile.
   */
  @Prop({ default: false })
  public authorized!: boolean;

  /**
   * The color for this container.
   */
  @Prop({
    default: () => {
      return { r: 255, g: 0, b: 255, a: 1.0 };
    }
  })
  public color!: IRgba;

  // The tabs to display in the container.
  private tabs_: string[] = [];

  // The state of the context button.
  private editMode_: boolean = false;
  private contextButtonVisible_: boolean = true;
  private contextButtonText_: string = "";

  /**
   * Indicates if the context button should be visible.
   * @return True if it should be visible, false otherwise.
   */
  public get contextButtonVisible() {
    if (this.entity === null) return false;
    let roles: string[] = this.$store.state.currentEntity.roles;
    if (
      this.$store.state.currentEntity.id === this.entity.id ||
      roles.indexOf("administrator") !== -1
    )
      return this.contextButtonVisible_;
    return false;
  }

  /**
   * Callback that responds to the entity being changed.
   * @param newValue The new entity.
   */
  @Watch("entity", { immediate: true })
  private onEntityChanged(newValue: IEntityInfo) {
    if (newValue === null) return;
    switch (this.entity.type) {
      case "person":
        this.tabs_ = ["Experience", "Connections", "Follows"];
        break;
      case "organization":
        this.tabs_ = ["Team", "Follows"]; // TODO:  "Media",
        break;
      default:
        throw new Error("Unknown type of entity encountered.");
    }
  }

  /**
   * Callback that responds to the context button being clicked.
   * @param event The object that enables interaction with the click event.
   */
  private onClickContextButton(event: Event) {
    // Find the container.
    let container: any = this.$refs.container;

    // Determine which tab we are on.
    switch (container.activeTab) {
      case "experience":
        container.$emit("contextButtonClicked", this.$refs.experience);
        this.$emit("contextButtonClicked", this.$refs.experience);
        break;
      case "connections":
        container.$emit("contextButtonClicked", this.$refs.connections);
        this.$emit("contextButtonClicked", this.$refs.connections);
        break;
      case "follows":
        container.$emit("contextButtonClicked", this.$refs.follows);
        this.$emit("contextButtonClicked", this.$refs.follows);
        break;
      case "team":
        container.$emit("contextButtonClicked", this.$refs.team);
        this.$emit("contextButtonClicked", this.$refs.team);
        break;
      case "media":
      default:
        throw new Error("Unknown experience type encountered.");
    }
  }

  /**
   * Callback that responds to the tab changing.
   * @param event The object that enables interaction with the click event.
   * @param newTab The new tab.
   */
  private onTabChanged(event: Event, newTab: string) {
    // Find the container.
    let container: any = this.$refs.container;
    this.editMode_ = false;
    switch (newTab) {
      case "experience":
        this.contextButtonVisible_ = true;
        this.contextButtonText_ = "+ Add Experience";
        if (container) container.$emit("tabChanged", this.$refs.experience);
        this.$emit("tabChanged", this.$refs.experience);
        break;
      case "connections":
        this.contextButtonVisible_ = true;
        this.contextButtonText_ = "+ Add Connection";
        if (container) container.$emit("tabChanged", this.$refs.connections);
        this.$emit("tabChanged", this.$refs.connections);
        break;
      case "follows":
        this.contextButtonVisible_ = this.entity.type === "person" ? true : false;
        this.contextButtonText_ = "+ Add Follow";
        if (container) container.$emit("tabChanged", this.$refs.follows);
        this.$emit("tabChanged", this.$refs.follows);
        break;
      case "team":
        this.contextButtonVisible_ = true;
        this.contextButtonText_ = "+ Add Team Member";
        if (container) container.$emit("tabChanged", this.$refs.team);
        this.$emit("tabChanged", this.$refs.team);
        break;
      case "media":
        this.contextButtonVisible_ = false;
        this.contextButtonText_ = "+ Add Media Mention";
        // TODO: Refs
        break;
      default:
        throw new Error("Unknown experience type encountered.");
    }
  }

  /**
   * Callback that responds to the current mode changing.
   * @param event The object that enables interaction with the change event.
   * @param isEdit A flag that indicates if edit mode has been enabled.
   * @param action The action that was taken.
   */
  private onModeChanged(event: Event, isEdit: boolean, action: string) {
    this.editMode_ = isEdit;
  }
}
