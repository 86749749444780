






























































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LoadingIndicator from "@/components/shared/LoadingIndicator.vue";
import { IEntityInfo } from "../../types";
import SearchServices from "../../services/SearchServices";

/**
 * Describes a function that responds to a submit event.
 */
export interface ISubmitHandler {
  (event?: Event): void;
}

/**
 * Responsible for handling the search bar.
 */
@Component({ components: { LoadingIndicator } })
export default class SearchTextBox extends Vue {
  /**
   * A flag that indicates if a border should be visible.
   */
  @Prop({ default: false })
  public noBorder!: boolean; // TODO: No border is not impemented yet.

  /**
   * A flag that indicates if links should be followed.
   */
  @Prop({ default: true })
  public followLinks!: boolean;

  /**
   * The type of entity to query.
   */
  @Prop({ default: "any" })
  public entityType!: string;

  /**
   * Callback that responds to the user submitting this search.
   */
  @Prop({ default: null })
  public submit!: ISubmitHandler;

  /**
   * The value of the text box.
   */
  @Prop({ default: "" })
  public value!: string;

  /**
   * The text to use for the placeholder.
   */
  @Prop({ default: null })
  public placeholder!: string;

  // The current suggestions.
  private text_: string = "";
  private wasEmpty_: boolean = true;
  private hasFocus_: boolean = false;
  private blurOnRouteChange_: boolean = false;
  private textSetBySelf_: boolean = false;
  private sentSubmit_: boolean = false;
  private searching_: boolean = false;
  private suggestions_: IEntityInfo[] = [];
  private timeoutHandle_: number | null = null;
  private requestId_: number = 0;

  /**
   * The class name for this component.
   * @return The class name.
   */
  private get className(): string {
    return this.noBorder ? "search-text no-border" : "search-text";
  }

  /**
   * Returns the suggested entity.
   * @return The suggested entity.
   */
  private get suggestion(): string {
    if (this.suggestions_.length === 0 || this.sentSubmit_ || !this.hasFocus_)
      return "";
    return this.suggestions_[0].type === "person"
      ? `${this.suggestions_[0].firstName} ${this.suggestions_[0].lastName}`
      : this.suggestions_[0].name;
  }

  /**
   * Returns the suggested entity filtered to appear like the user's current text.
   * @return the filtered suggestion.
   */
  private get filteredSuggestion(): string {
    if (this.suggestions_.length === 0) return "";
    let suggestion = this.suggestion;
    return this.text_ + this.suggestion.substring(this.text_.length);
  }

  /**
   * Returns the text for the placeholder.
   * @return The placeholder text.
   */
  private get placeholderText(): string {
    if (this.placeholder !== null) return this.placeholder;
    switch (this.entityType) {
      case "person":
        return "Person";
      case "organization":
        return "Organization";
      case "any":
        return "Person or organization";
      default:
        throw new Error("Unknown entity type encountered.");
    }
  }

  /**
   * The source for the search image.
   * @param iconName THe name of the icon.
   * @return The source.
   */
  private getImageSrc(iconName: string): string {
    return this.$store.getters.imageSrc(iconName);
  }

  /**
   * Callback that responds to the user pressing enter.
   */
  private onSubmit() {
    if (this.submit !== null) this.submit(new Event("submit"));
    this.sentSubmit_ = true;
    this.wasEmpty_ = true;
  }

  /**
   * Callback that responds to the user pressing enter.
   * @param event The object that enables interaction with the event.
   */
  private onAcceptSuggestion(event: Event) {
    if (this.suggestions_.length === 0) return;
    let text = this.suggestion;
    if (this.text_ !== text) {
      this.suggestions_ = [];
      this.sentSubmit_ = true;
      this.wasEmpty_ = true;
      this.textSetBySelf_ = true;
      this.text_ = text;
      event.preventDefault();
    }
  }

  /**
   * Callback that responds to the user clicking on a link.
   * @param entity The entity that was clicked.
   */
  private onClickLink(entity: IEntityInfo) {
    if (this.followLinks) this.$router.push(`/profile/${entity.id}`);
    else {
      this.suggestions_ = [];
      this.sentSubmit_ = true;
      this.wasEmpty_ = true;
      this.textSetBySelf_ = true;
      this.text_ =
        entity.type === "person"
          ? `${entity.firstName} ${entity.lastName}`
          : entity.name;
    }
  }

  /**
   * Callback that responds to the user clicking on the search button.
   */
  @Watch("text_")
  private onChange() {
    this.$emit("input", this.text_);

    // Don't process an event that was sent by this component.
    if (this.textSetBySelf_) {
      this.textSetBySelf_ = false;
      return;
    }

    // Don't process an empty search.
    if (this.text_ === "") {
      this.wasEmpty_ = true;
      this.suggestions_ = [];
      return;
    }

    // If the previous entry was empty, indicate that we are searching right away.
    if (this.wasEmpty_) {
      this.wasEmpty_ = false;
      this.searching_ = true;
    }

    // Try the search.
    this.sentSubmit_ = false;
    if (this.timeoutHandle_ !== null) clearTimeout(this.timeoutHandle_);
    this.timeoutHandle_ = setTimeout(this.onCommenceSearch, 500);
  }

  /**
   * Callback that responds to a search request.
   */
  private onCommenceSearch() {
    this.searching_ = true;
    this.suggestions_ = [];
    let requestId = ++this.requestId_;
    this.timeoutHandle_ = null;
    SearchServices.searchEntities(
      this.entityType,
      this.text_ + ".",
      ["name"],
      0,
      8
    )
      .then(results => {
        if (this.requestId_ !== requestId) return;
        this.suggestions_ = results.data;
        this.searching_ = false;
      })
      .catch(error => {
        if (this.requestId_ !== requestId) return;
        this.suggestions_ = [];
        this.searching_ = false;
      });
  }

  /**
   * Callback that responds to the route changing.
   */
  @Watch("$route")
  private onRouteChanged() {
    if (this.blurOnRouteChange_) this.blurOnRouteChange_ = false;
  }

  /**
   * Callback that responds to the text box becoming focused.
   */
  private onFocus() {
    this.hasFocus_ = true;
  }

  /**
   * Callback that responds to the text box losing focus.
   */
  private onBlur(event: FocusEvent) {
    // We can't unfocus immediatly if the target is an anchor otherwise the redirect gets cancelled.
    if (!(event.relatedTarget instanceof HTMLElement)) return;
    let target: HTMLElement = event.relatedTarget as HTMLElement;
    if (target.tagName === "A") {
      // TODO: We need to make sure this is a click event.  If we got a blur because a child was focused, this is ok.
      let parent: HTMLElement | null = target.parentElement;
      while (parent !== null) {
        if (parent.className.indexOf("search-text") !== -1) {
          this.blurOnRouteChange_ = true;
          break;
        }
        parent = parent.parentElement;
      }
    }
    this.hasFocus_ = false;
    this.sentSubmit_ = false;
  }
}
