






import { Component, Prop, Vue } from "vue-property-decorator";
import { IRgba } from "../../types";

/**
 * Describes a function that responds to a button click.
 */
export interface IClickHandler {
  (event?: Event): void;
}

/**
 * Responsible for handling a button widget
 */
@Component
export default class Button extends Vue {
  /**
   * The callback that responds to a click event.
   */
  @Prop({ default: null })
  public click!: IClickHandler;

  /**
   * A flag that indicates if the button is enabled.
   */
  @Prop({ default: true })
  public enabled!: boolean;

  /**
   * The color for this container.
   */
  @Prop({
    default: () => {
      return { r: 113, g: 106, b: 106, a: 1.0 };
    }
  })
  public color!: IRgba;

  /**
   * The class name for this component.
   * @return The class name.
   */
  public get className(): string {
    return this.enabled === true ? "portal-button" : "portal-button disabled";
  }

  /**
   * The style for this component.
   * @return The style.
   */
  public get style(): string {
    return this.enabled
      ? `background: rgba(${this.color.r}, ${this.color.g}, ${this.color.b}, ${this.color.a})`
      : `background: rgba(119,119,119,1.0)`;
  }

  /**
   * Callback that responds to the button being clicked.
   * @param event The object that describes the event.
   */
  private onClick(event: Event) {
    if (this.enabled === false) return;
    if (this.click !== null) this.click(event);
  }
}
