import { ICacheInfo, IApiResponseWithData, IEntityInfo } from "@/types";
import Constants from "./Constants";
import ServiceHelper from "./ServiceHelper";

/**
 * The class used to query the back-end api for search results.
 */
export default abstract class SearchServices {
  /**
   * Queries for summarized information about a subset of entities.
   * @param type The type of entity to get.
   * @param query The query to make.
   * @param fields The fields to query.
   * @param offset The first entity to fetch.
   * @param count The number of entities to fetch.
   * @return The entities.
   */
  public static async searchEntities(
    type: string,
    query: string = "",
    fields: string[] = [],
    offset: number = 0,
    count: number = 10
  ): Promise<IApiResponseWithData<IEntityInfo[]>> {
    let queryParams = `?query=${query}&fields=${fields.join(
      ","
    )}&offset=${offset}&count=${count}&type=${type}`;
    return ServiceHelper.processResponse(
      ServiceHelper.get(`${Constants.endpoints.searchEntities}${queryParams}`)
    );
  }

  /**
   * Queries for the current size of the cache.
   * @return The results.
   */
  public static async getCacheSize(): Promise<
    IApiResponseWithData<ICacheInfo>
  > {
    return ServiceHelper.processResponse(
      ServiceHelper.get(Constants.endpoints.getCacheSize)
    );
  }

  /**
   * Sends a request to rebuild the cache.
   * @return The results.
   */
  public static async rebuildCache(): Promise<
    IApiResponseWithData<ICacheInfo>
  > {
    return ServiceHelper.processResponse(
      ServiceHelper.post(Constants.endpoints.rebuildCache)
    );
  }
}
