











import { Component, Prop, Vue } from "vue-property-decorator";
import { IRgba } from "../../types";

/**
 * Responsible for displaying a progress indicator.
 */
@Component
export default class ProgressIndicator extends Vue {
  /**
   * The color of the loading bar
   */
  @Prop({
    default: () => {
      return { r: 43, g: 194, b: 83, a: 1.0 };
    }
  })
  public barColor!: IRgba;

  /**
   * A flag that indicates if this bar should be rounded.
   */
  @Prop({ default: true })
  public rounded!: boolean;

  /**
   * A flag that indicates if the bar should be animated.
   */
  @Prop({ default: true })
  public animated!: boolean;

  /**
   * The color of the loading bar
   */

  @Prop({
    default: () => {
      return { r: 255, g: 255, b: 255, a: 1.0 };
    }
  })
  public textColor!: IRgba;

  /**
   * The percentage the bar has been filled.
   */

  @Prop({ default: 0.0 })
  public percent!: number;

  /**
   * The status text to display.
   */
  @Prop({ default: "" })
  public text!: string;

  /**
   * The class name to use for the progress indicator.
   */
  public get className(): string {
    let rounded = this.rounded ? " rounded" : "";
    let animated = this.animated ? " animated" : "";
    return `progress-container${rounded}${animated}`;
  }

  /**
   * The style used to render the loading bar.
   * @return The style.
   */
  public get styleBar(): string {
    let lightColor = this.increaseBrightness(this.barColor, 0.25);
    return (
      `background-color: rgba(${this.barColor.r},${this.barColor.g},${this.barColor.b}, ${this.barColor.a});` +
      `background-image: linear-gradient(` +
      `to bottom,` +
      `rgba(${this.barColor.r},${this.barColor.g},${this.barColor.b}, ${this.barColor.a}) 37%,` +
      `rgba(${lightColor.r},${lightColor.g},${lightColor.b}, ${lightColor.a}) 69%` +
      `);` +
      `width: ${this.percent * 100}%;`
    );
  }

  /**
   * The style used render the span.
   * @return The style.
   */
  public get styleStatus(): string {
    return `color: rgba(${this.textColor.r},${this.textColor.g},${this.textColor.b}, ${this.textColor.a})`;
  }

  /**
   * Increases the brightness of a color.
   * @param color The color to increase.
   * @param percent The amount of brightness to increase the color by.
   * @return The new color.
   */
  private increaseBrightness(color: IRgba, percent: number): IRgba {
    return {
      r: Math.min(255, color.r + (256 - color.r) * percent),
      g: Math.min(255, color.g + (256 - color.g) * percent),
      b: Math.min(255, color.b + (256 - color.b) * percent),
      a: color.a
    };
  }
}
