import { IMessage, IApiResponseWithData } from "@/types";
import Constants from "./Constants";
import ServiceHelper from "./ServiceHelper";

/**
 * The class used to query the back-end api for messages sent to the current user.
 */
export default abstract class MessageServices {
  /**
   * Queries for messages sent to the current user.
   * @return The messages.
   */
  public static async getMessages(): Promise<IApiResponseWithData<IMessage[]>> {
    return ServiceHelper.processResponse(
      ServiceHelper.get(Constants.endpoints.messages)
    );
  }
}
