















import SearchBar from "@/components/shared/SearchBar.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IEntityInfo } from "../../types";

/**
 * Responsible for handling the main navigation.
 */
@Component({ components: { SearchBar } })
export default class MainNavigation extends Vue {
  private hasError_: boolean = false;

  /**
   * Returns the link to the company profile being managed by the current entity.
   * @return The router link.
   */
  public get companyLink(): string {
    let currentEntity: IEntityInfo = this.$store.state.currentEntity;
    if (!currentEntity.manages || currentEntity.manages.length === 0)
      return "#";
    let id: number = currentEntity.manages[0];
    return `/profile/${id}`;
  }

  /**
   * Indicates if the current user has a company.
   * @return True if the current user has a company, false otherwise.
   */
  public get hasCompany(): boolean {
    let currentEntity: IEntityInfo = this.$store.state.currentEntity;
    return currentEntity.manages && currentEntity.manages.length > 0;
  }

  /**
   * A flag that indicates if the current user is authorized.
   * @return True if the current user is authorized, false otherwise.
   */
  public get isAuthorized(): boolean {
    let roles: string[] = this.$store.state.currentEntity.roles;
    return roles.indexOf("administrator") !== -1;
  }

  /**
   * Callback that responds to a route being chnaged.
   */
  @Watch("$route", { immediate: true })
  private onRouteChanged(newRoute: any) {
    this.hasError_ = newRoute.name === "error" ? true : false;
  }
}
