import { IApiError } from "@/types";
import axios, { AxiosResponse, AxiosInstance, AxiosRequestConfig } from "axios";
import Constants from "./Constants";

/**
 * The class used to help other services.
 */
export default abstract class ServiceHelper {
  /**
   * The client used to make requests.
   */
  private static client_ = axios.create({
    baseURL: Constants.endpoint,
    headers: {
      "Content-Type": "application/json",
      "X-WP-Nonce": "VueData" in window ? (window as any).VueData.nonce : ""
    }
  });

  /**
   * The authentication nonce.
   * @return The nonce.
   */
  public static get authenticationToken(): string {
    if ("VueData" in window) return (window as any).VueData.nonce;
    else return "";
  }

  /**
   * The authentication nonce.
   * @param token The token used for authentications.
   */
  public static set authenticationToken(token: string) {
    (window as any).VueData = { nonce: token };
    ServiceHelper.client_.defaults.headers["X-WP-Nonce"] = token;
  }

  /**
   * Makes a get request.
   * @param url The url to make a request to.
   * @param config The configuration to use.
   * @return The response.
   */
  public static async get<T extends any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return ServiceHelper.client_.get<T>(url, config);
  }

  /**
   * Makes a get request.
   * @param url The url to make a request to.
   * @param data The data to send.
   * @param config The configuration to use.
   * @return The response.
   */
  public static async post<T extends any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return ServiceHelper.client_.post<T>(url, data, config);
  }

  /**
   * Makes a put request.
   * @param url The url to make a request to.
   * @param data The data to send.
   * @param config The configuration to use.
   * @return The response.
   */
  public static async put<T extends any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return ServiceHelper.client_.put<T>(url, data, config);
  }

  /**
   * Processes an axios response and translates it to data.
   * @param response The response to process.
   */
  public static async processResponse<T>(
    response: Promise<AxiosResponse>
  ): Promise<T> {
    return new Promise<T>((accept, reject) => {
      response
        .then(response => {
          let data = JSON.parse(response.data);
          if (data.error === false) accept(data as T);
          else {
            reject({
              error: true,
              message: data.message,
              status: response.status
            } as IApiError);
          }
        })
        .catch(error => {
          let message = error.message;
          if (error.response && error.response.data) {
            if (typeof error.response === "string") {
              let data = JSON.parse(error.response);
              message = data.message;
            } else if (
              typeof error.response === "object" &&
              "data" in error.response
            ) {
              if (typeof error.response.data === "string") {
                try {
                  let data = JSON.parse(error.response.data);
                  message = data.message;
                } catch (e) {
                  message = error.response.data;
                }
              } else if (typeof error.response.data === "object")
                message = error.response.data.message;
            }
          }
          reject({
            error: true,
            message: message,
            status: error.status
          } as IApiError);
        });
    });
  }
}
