


























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import EntityCount from "@/components/admin/EntityCount.vue";
import ImportEntity from "@/components/admin/ImportEntity.vue";
import ImportAvatar from "@/components/admin/ImportAvatar.vue";
import ImportTeam from "@/components/admin/ImportTeam.vue";
import CacheSize from "@/components/admin/CacheSize.vue";

/**
 * Renders the profile page.
 */
@Component({
  components: { EntityCount, ImportEntity, ImportAvatar, ImportTeam, CacheSize }
})
export default class Admin extends Vue {
  private authorized_: boolean = false;

  /**
   * The location of the image to display.
   */
  public get imageSrc(): string {
    return this.$store.getters.imageSrc("notification-error.svg");
  }

  /**
   * Called in response to the component being created.
   */
  public created() {
    let roles: string[] = this.$store.state.currentEntity.roles;
    if (roles.indexOf("administrator") !== -1) this.authorized_ = true;
  }
}
