


































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IRgba } from "../../types";

/**
 * Describes an option for a multi-select.
 */
export interface IOption {
  /**
   * The label to display.
   */
  label: string;
  /**
   * The value to select.
   */
  value: string;
}

/**
 * Responsible for handling a multi-select widget.
 */
@Component
export default class MultiSelect extends Vue {
  /**
   * The options to present in the multi select.
   */
  @Prop({ default: () => [] })
  public options!: IOption[];

  /**
   * The selected options.
   */
  @Prop({ default: () => [] })
  public value!: string[];

  /**
   * A flag that indicates if the select is expanded.
   */
  @Prop({ default: false })
  public expanded!: boolean;

  // Expanded.
  private value_: string[] = [];
  private expanded_: boolean = false;

  /**
   * Returns the name of the class for this select.
   * @return The class name.
   */
  private get className(): string {
    return this.expanded_ === true ? "multi-select expanded" : "multi-select";
  }

  /**
   * Returns the text to display on the select button.
   * @return The text.
   */
  private get selectButtonText(): string {
    if (
      this.value === undefined ||
      this.value === null ||
      this.value.length === 0
    )
      return "Select options";
    switch (this.value.length) {
      case 1:
        return this.getLabel(this.value[0]);
      case 2:
        return (
          this.getLabel(this.value[0]) + " and " + this.getLabel(this.value[1])
        );
      default:
        if (this.value.length < 3) {
          return (
            this.value
              .slice(0, this.value.length - 1)
              .map(v => this.getLabel(v))
              .join(", ") +
            ", and " +
            this.getLabel(this.value[this.value.length - 1])
          );
        } else return `${this.value.length} items`;
    }
  }

  /**
   * Converts a value to a label.
   * @param value The value to convert.
   * @return The converted value.
   */
  private getLabel(value: string): string {
    for (let option of this.options) {
      if (option.value === value) return option.label;
    }
    throw new Error(`Unknown value '${value}' encountered.`);
  }

  /**
   * Callback that responds to the value being changed.
   * @param newValue The new value.
   */
  @Watch("value", { immediate: true })
  private onValueChanged(newValue: string[]) {
    if (
      newValue === undefined ||
      newValue === null ||
      !Array.isArray(newValue)
    ) {
      this.value_ = [];
      this.$emit("update:value", this.value_);
    } else this.value_ = newValue;
  }

  /**
   * Callback that responds to the expanded property being changed.
   * @param newValue The new value.
   */
  @Watch("expanded", { immediate: true })
  private onExpandedChanged(newValue: boolean) {
    this.expanded_ = newValue;
  }

  /**
   * Callback that responds to the button being clicked.
   * @param event The object that describes the event.
   */
  private onClick(event: Event) {
    this.expanded_ = !this.expanded_;
    this.$emit("update:expanded", this.expanded_);
    if (this.expanded_)
      document.body.addEventListener("click", this.onClickBody);
    else document.body.removeEventListener("click", this.onClickBody);
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * Callback that responds to a click anywhere on the body.
   * @param event The object that describes the event.
   */
  private onClickBody(event: Event) {
    if (this.expanded_) {
      document.body.removeEventListener("click", this.onClickBody, true);
      this.expanded_ = !this.expanded_;
      this.$emit("update:expanded", this.expanded_);
    }
  }

  /**
   * Callback that responds to a value button being clicked.
   * @param event The object that describes the event.
   */
  private onClickValue(event: Event) {
    // Locate the list item.
    if (
      !(event.target instanceof HTMLElement) ||
      event.target.parentElement === null
    )
      return;
    let parent: HTMLElement | null = event.target.parentElement;
    while (parent != null && parent.nodeName !== "LI") {
      parent = parent.parentElement;
    }
    if (parent === null) return;

    // Find the value being selected.
    let data = parent.attributes.getNamedItem("data-value");
    if (data === null) return;
    let selected = parent.classList.contains("selected");

    // Update the value.
    let index = this.value_.indexOf(data.value);
    if (index === -1) this.value_.push(data.value);
    else this.value_.splice(index, 1);
    this.$emit("update:value", this.value_);
    event.preventDefault();
    event.stopPropagation();
  }
}
