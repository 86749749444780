// Determine the endpoint.
import store from "@/store/index";
let windowAsAny: any = window as any;
let apiUrl = store.state.apiUrl;
if (windowAsAny.VueData) apiUrl = windowAsAny.VueData.apiUrl;

/**
 * The constants for the services.
 */
export default abstract class Constants {
  /**
   * The base endpoint for the api.
   */
  public static readonly endpoint = apiUrl + "/";

  /**
   * The path to the portal api endpoints.
   */
  public static readonly pathPortal = "portal/v1/";

  /**
   * The path to the member api endpoints.
   */
  public static readonly pathMembers = "members/v1/";

  /**
   * The individual endpoints.
   */
  public static readonly endpoints = {
    /**
     * The endpoint for authenticate queries.
     */
    authenticate: Constants.pathPortal + "authenticate/",
    /**
     * The endpoint for entity queries.
     */
    entity: Constants.pathPortal + "entity/",
    /**
     * The endpoint for searching entities.
     */
    searchEntities: Constants.pathPortal + "search_entities/",
    /**
     * The endpoint for updating the entity.
     */
    updateEntity: Constants.pathPortal + "entity/",
    /**
     * The endpoint for claiming ownership an entity.
     */
    claimEntity: Constants.pathPortal + "claim_entity/",
    /**
     * The endpoint for un-claiming ownership of an entity.
     */
    unclaimEntity: Constants.pathPortal + "unclaim_entity/",
    /**
     * The endpoint for finding all claims.
     */
    getClaims: Constants.pathPortal + "claims/",
    /**
     * The endpoint for counting the entities.
     */
    entityCount: Constants.pathPortal + "entity_count/",
    /**
     * The endpoint for uploading a profile image.
     */
    uploadProfileImage: Constants.pathPortal + "upload_profile_image/",
    /**
     * The endpoint for importing an entity.
     */
    importEntity: Constants.pathPortal + "import_entity/",
    /**
     * The endpoint for importing a profile image.
     */
    importProfileImage: Constants.pathPortal + "import_profile_image/",
    /**
     * The endpoint for getting the cache size.
     */
    getCacheSize: Constants.pathPortal + "get_cache_size/",
    /**
     * The endpoint for rebuilding the cache.
     */
    rebuildCache: Constants.pathPortal + "rebuild_cache/",
    /**
     * The endpoint for the latest posts.
     */
    latestPosts: Constants.pathPortal + "latest_posts/",
    /**
     * The endpoint for the connections.
     */
    connections: Constants.pathPortal + "connections/",
    /**
     * The endpoint for making a connection.
     */
    makeConnection: Constants.pathPortal + "make_connection/",
    /**
     * The endpoint for importing a connection.
     */
    importConnection: Constants.pathPortal + "import_connection/",
    /**
     * The endpoint for updating a connection.
     */
    updateConnection: Constants.pathPortal + "update_connection/",
    /**
     * The endpoint for removing a connection.
     */
    removeConnection: Constants.pathPortal + "remove_connection/",
    /**
     * The endpoint for confirming a connection.
     */
    verifyConnection: Constants.pathPortal + "verify_connection/",
    /**
     * The endpoint for the messages.
     */
    messages: Constants.pathMembers + "messages/"
  };
}
