















import EntitySummary from "@/components/shared/EntitySummary.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IEntityInfo, IPostInfo } from "../../types";

/**
 * Responsible for rendering a listing of posts.
 */
@Component({ components: { EntitySummary } })
export default class PostListing extends Vue {
  /**
   * The list of known posts.
   */
  @Prop({ default: () => [] })
  public posts!: IPostInfo[];

  /**
   * Returns the source for the image.
   * @param post The post to get the image for.
   * @return The image source.
   */
  public getProfileImageSrc(post: IPostInfo): string {
    if (post.imgSrc === undefined || post.imgSrc === "") {
      if (post.entityImgSrc === undefined || post.entityImgSrc === "")
        return this.$store.getters.imageSrc("profile-placeholder-member.svg");
      else return this.$store.getters.profileSrc(post.entityImgSrc);
    }
    return this.$store.getters.imageSrc("profile-placeholder-member.svg");
  }

  /**
   * Returns the link to a post.
   * @param post The post to get the href for.
   * @return The link.
   */
  public getHref(post: IPostInfo): string {
    return post.url; // TODO: translate urls to relative.
  }

  /**
   * Returns the date for a post.
   * @param post The post to get the date for.
   * @return The date.
   */
  public getPostedDate(post: IPostInfo): string {
    let now = new Date().getTime();
    let then = new Date(post.createdDate * 1000).getTime();
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
    var elapsed = now - then;
    if (elapsed < msPerMinute)
      return Math.round(elapsed / 1000) + " seconds ago";
    else if (elapsed < msPerHour)
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    else if (elapsed < msPerDay)
      return Math.round(elapsed / msPerHour) + " hours ago";
    else if (elapsed < msPerMonth)
      return "Approximately " + Math.round(elapsed / msPerDay) + " days ago";
    else if (elapsed < msPerYear)
      return (
        "Approximately " + Math.round(elapsed / msPerMonth) + " months ago"
      );
    else
      return "Approximately " + Math.round(elapsed / msPerYear) + " years ago";
  }
}
