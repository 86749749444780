
import ConnectionPane from "@/components/profile/ConnectionPane.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IConnectionPaneParams } from "./ConnectionPane.vue";
import { IConnectionInfo, IAction, IEntityInfo, ITag } from "../../types";
import ConnectionServices from "../../services/ConnectionServices";
import EntityServices from "../../services/EntityServices";

/**
 * Responsible for rendering a "experience" widget.
 */
@Component
export default class Experience extends ConnectionPane {
  // The parameters required to run this pane.
  protected parameters_: IConnectionPaneParams = {
    name: "Experience",
    plural: "Experience",
    type: "team",
    filter: "organization",
    entityMustExist: false,
    includeIncoming: true,
    includeOutgoing: true,
    conflictResolution: "from",
    metaDataInfo: [
      {
        propertyName: "position",
        label: "Position *",
        field: "text",
        colspan: 2,
        getter: c => c.position
      },
      {
        propertyName: "startDate",
        label: "Start Date *",
        field: "date",
        colspan: 1,
        getter: c => c.startDate
      },
      {
        propertyName: "endDate",
        label: "End Date *",
        field: "date",
        colspan: 1,
        getter: c => c.endDate
      },
      {
        propertyName: "description",
        label: "Description *",
        field: "textarea",
        colspan: 2,
        getter: c => c.description
      }
    ]
  };

  /**
   * Returns the actions for the given connection.
   * @param connection The connection to get the actions for.
   * @return The actions for the given connection.
   */
  protected getActions(connection: IConnectionInfo): IAction[] {
    let actions: IAction[] = [];
    let roles: string[] = this.$store.state.currentEntity.roles;
    let isSender = this.entity.id === connection.from.id;
    let canEdit = isSender || this.authorized; // TODO: Can claim must include any claims
    let canClaim = isSender && (!connection.to.claims || connection.to.claims.length === 0);
    let canRenounce =
      isSender &&
      (connection.to.claims && connection.to.claims.length > 0) &&
      (connection.metaData.claimId === this.$store.state.currentEntity.id ||
        this.authorized);

    // If we have the ability to edit, place the buttons.
    if (canEdit) {
      actions.push({
        label: "Edit Experience",
        tag: "edit",
        colorText: { r: 255, g: 255, b: 255, a: 1 },
        colorBackground: { r: 113, g: 106, b: 106, a: 1 },
        callback: (event, action) =>
          this.onClickAction(event, connection, action)
      });
    }

    // If the user can claim the organization.
    if (canClaim) {
      actions.push({
        label: "Claim Organization",
        tag: "claim",
        colorText: { r: 255, g: 255, b: 255, a: 1 },
        colorBackground: { r: 113, g: 106, b: 106, a: 1 },
        callback: (event, action) =>
          this.onClickAction(event, connection, action)
      });
    }

    // If the user can renounce the organization.
    if (canRenounce) {
      actions.push({
        label: "Remove Claim",
        tag: "renounce",
        colorText: { r: 255, g: 255, b: 255, a: 1 },
        colorBackground: { r: 113, g: 106, b: 106, a: 1 },
        callback: (event, action) =>
          this.onClickAction(event, connection, action)
      });
    }

    // If we have the ability to delete.
    if (canEdit) {
      actions.push({
        label: "Delete Experience",
        tag: "delete",
        colorText: { r: 255, g: 255, b: 255, a: 1 },
        colorBackground: { r: 204, g: 51, b: 0, a: 1 },
        callback: (event, action) =>
          this.onClickAction(event, connection, action)
      });
    }
    return actions;
  }

  /**
   * Returns the tags for the given connection.
   * @param connection The connection to get the tags for.
   * @return The tags for the given connection.
   */
  protected getTags(connection: IConnectionInfo): ITag[] {
    let tags: ITag[] = [];
    let claims =
      this.entity.id === connection.from.id
        ? connection.to.claims
        : connection.from.claims;
    claims = claims ? claims : [];

    // Add the unverified tag.
    if (connection.bidirectional === false) {
      tags.push({
        label: "Unverified",
        href: "",
        colorText: { r: 0, g: 0, b: 0, a: 1 },
        colorBackground: { r: 190, g: 190, b: 190, a: 1 }
      });
    }

    // Add the owner tag.
    if (claims.some(c => c === this.entity.id)) {
      tags.push({
        label: "Owner",
        href: "",
        colorText: { r: 0, g: 0, b: 0, a: 1 },
        colorBackground: { r: 190, g: 190, b: 190, a: 1 }
      });
    }

    return tags;
  }

  /**
   * Claims an organization on behalf of the specified user.
   * @param connection The connection that represents the relationship between the user and organization.
   */
  private claimOrganization(connection: IConnectionInfo): void {
    this.hasData = false;
    EntityServices.claimEntity(connection.from.id, connection.to.id)
      .then(results => {
        this.refreshConnections();
      })
      .catch(error => {
        console.log(error);
      });
  }

  /**
   * Renounces the claim of an organization on behalf of the specified user.
   * @param connection The connection that represents the relationship between the user and organization.
   */
  private unclaimOrganization(connection: IConnectionInfo): void {
    this.hasData = false;
    EntityServices.unclaimEntity(connection.from.id, connection.to.id)
      .then(results => {
        this.refreshConnections();
      })
      .catch(error => {
        console.log(error);
      });
  }

  /**
   * Callback that responds to an action button being clicked.
   * @param event The object that enables interaction with the event.
   * @param connection The connection that the action is connected to.
   * @param action The action that was clicked.
   */
  private onClickAction(
    event?: Event,
    connection?: IConnectionInfo,
    action?: IAction
  ): void {
    if (!event || !connection || !action) return;
    switch (action.tag) {
      case "edit":
        this.editConnection(connection);
        break;
      case "delete":
        this.removeConnection(connection);
        break;
      case "claim":
        this.claimOrganization(connection);
        break;
      case "renounce":
        this.unclaimOrganization(connection);
        break;
      default:
        throw new Error(`Unexpected action '${action.tag}' encountered.`);
    }
  }
}
