import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { ZipJs } from "./zipjs";

Vue.config.productionTip = false;
ZipJs.workerScriptsPath = store.state.appUrl + "/js/vendor/zip-js/";
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
