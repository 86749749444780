





























import Container from "@/components/shared/Container.vue";
import SearchBar from "@/components/shared/SearchBar.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IRgba } from "../../types";

/**
 * Responsible for rendering search filters widget.
 */
@Component({ components: { SearchBar, Container } })
export default class SearchFilters extends Vue {
  /**
   * The alphabet used for rendering list items.
   */
  private alphabet_: string[] = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z"
  ];

  // THe type of search to perform.
  private searchType_: string = "name";

  /**
   * Returns the link for a person query.
   * @param letter The letter to query.
   * @return The link.
   */
  public getLink(letter: string): string {
    return `/search?query=${letter}.&fields=name`;
  }
}
