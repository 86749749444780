import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { IEntityInfo } from "@/types";

Vue.use(Vuex);

/**
 * Describes an object that stores information.
 */
export interface IStore {
  /**
   * The currently logged in entity.
   */
  currentEntity: IEntityInfo;

  /**
   * The url for the application.
   */
  appUrl: string;

  /**
   * The url for file uploads.
   */
  uploadsUrl: string;

  /**
   * The api url.
   */
  apiUrl: string;

  /**
   * The application version.
   */
  version: string;
}

// Determine the urls for files.
let windowAsAny: any = window as any;
let appUrl = process.env.BASE_URL.substring(0, process.env.BASE_URL.length - 1);
let uploadsUrl = "http://localhost/wp-content/uploads";
let apiUrl = "http://localhost/api/";
let version = "Debug Mode";
if (windowAsAny.VueData) {
  appUrl = windowAsAny.VueData.appUrl;
  uploadsUrl = windowAsAny.VueData.uploadsUrl;
  apiUrl = windowAsAny.VueData.apiUrl;
  version = `Version: ${windowAsAny.VueData.version}`;
}

/**
 * The options used to create the store.
 */
const options: StoreOptions<IStore> = {
  state: {
    currentEntity: { id: 0 } as IEntityInfo,
    appUrl: appUrl,
    uploadsUrl: uploadsUrl,
    apiUrl: apiUrl,
    version: version
  },
  getters: {
    imageSrc: state => (relativeUrl: string) => {
      return state.appUrl + "/img/" + relativeUrl;
    },
    profileSrc: state => (relativeUrl: string) => {
      return state.uploadsUrl + "/profile-pictures/" + relativeUrl;
    }
  }
};

export default new Vuex.Store<IStore>(options);
