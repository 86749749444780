import { IApiResponse } from "@/types";
import Constants from "./Constants";
import ServiceHelper from "./ServiceHelper";

/**
 * The class used to query the back-end api for entity info.
 */
export default abstract class EntityServices {
  /**
   * Queries for the currently logged in entity.
   * @return The entity.
   */
  public static async authenticate(): Promise<IApiResponse> {
    return ServiceHelper.processResponse<IApiResponse>(
      ServiceHelper.get<IApiResponse>(Constants.endpoints.authenticate)
    );
  }
}
