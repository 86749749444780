import { render, staticRenderFns } from "./EntitySummary.vue?vue&type=template&id=bd8099b0&scoped=true&"
import script from "./EntitySummary.vue?vue&type=script&lang=ts&"
export * from "./EntitySummary.vue?vue&type=script&lang=ts&"
import style0 from "./EntitySummary.vue?vue&type=style&index=0&id=bd8099b0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd8099b0",
  null
  
)

export default component.exports