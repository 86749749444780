


































import { Component, Prop, Vue } from "vue-property-decorator";
import AdminWidget from "./AdminWidget.vue";
import ProgressIndicator from "@/components/shared/ProgressIndicator.vue";
import LoadingIndicator from "@/components/shared/LoadingIndicator.vue";
import SearchServices from "../../services/SearchServices";
import { IRgba, ICacheInfo } from "../../types";
import Button from "@/components/shared/Button.vue";

/**
 * Responsible for handling an entity count widget.
 */
@Component({
  components: { AdminWidget, LoadingIndicator, Button, ProgressIndicator }
})
export default class EntityCount extends Vue {
  /**
   * The color for this container.
   */
  @Prop({
    default: () => {
      return { r: 95, g: 186, b: 87, a: 1.0 };
    }
  })
  public color!: IRgba;

  /**
   * The color of the info button.
   * @return The color.
   */
  public get infoButtonColor(): IRgba {
    return { r: 62, g: 112, b: 211, a: 1.0 };
  }

  /**
   * The color the loading indicator should be.
   * @return The color
   */
  public get loadingColor(): IRgba {
    return { r: 255, g: 255, b: 255, a: 1.0 };
  }

  // The cache size.
  private result_: ICacheInfo | null = null;

  // A flag that indicates if buttons may be pushed.
  private buttonsEnabled_: boolean = true;

  /**
   * The last update time.
   * @return The time.
   */
  public get updateTime(): string {
    return "Last updated at " + new Date().toLocaleTimeString();
  }

  /**
   * Called when the widget is added to the DOM tree.
   */
  public mounted() {
    SearchServices.getCacheSize().then(results => {
      this.result_ = results.data;
    });
  }

  /**
   * Callback that responds to the user clicking the rebuild button.
   * @param event The object that enables interaction with the event.
   */
  private onClickRebuild(event: Event) {
    this.buttonsEnabled_ = false;
    SearchServices.rebuildCache()
      .then(results => {
        this.result_ = results.data;
        this.buttonsEnabled_ = true;
      })
      .catch(error => {
        this.buttonsEnabled_ = true;
      });
  }

  /**
   * Converts the specified number of bytes to human-readable text.
   * @param bytes The number of bytes to format.
   * @param use1024 A flag that indicates if we are using the marketing 1000 bytes per kb or 1024 bytes per kb.
   * @return The formatted size.
   */
  private formatSize(bytes: number, use1024: boolean = true): string {
    var thresh = use1024 ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) return bytes + "B";
    var units = use1024
      ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
      : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
    var u = -1;
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return bytes.toFixed(1) + units[u];
  }
}
