


































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { IEntityInfo, IRgba, IApiResponseWithData } from "../types";
import EntityServices from "../services/EntityServices";
import EntityProfile from "@/components/profile/EntityProfile.vue";
import Needs from "@/components/profile/Needs.vue";
import SocialMedia from "@/components/profile/SocialMedia.vue";
import Container from "@/components/shared/Container.vue";
import ConnectionsContainer from "@/components/profile/ConnectionsContainer.vue";
import LoadingIndicator from "@/components/shared/LoadingIndicator.vue";
import ConnectionServices from "../services/ConnectionServices";

/**
 * Renders the profile page.
 */
@Component({
  components: {
    EntityProfile,
    Container,
    LoadingIndicator,
    SocialMedia,
    Needs,
    ConnectionsContainer
  }
})
export default class Profile extends Vue {
  /**
   * The id of the profile being viewed.
   */
  @Prop({ default: null })
  public id!: number;

  /**
   * The element to focus.
   */
  @Prop({ default: "" })
  public focus!: string;

  /**
   * A flag that indicates if data has been loaded.
   */
  public hasData: boolean = false;

  /**
   * The entity to render information for.
   */
  private entity_: IEntityInfo | null = null;

  /**
   * The color of this profile.
   */
  private color_: IRgba | null = null;

  /**
   * A flag that indicates if the current user can edit this entity.
   */
  private isAuthorized_: boolean = false;

  /**
   * The class name for the current view.
   * @return The class name.
   */
  public get className(): string {
    if (this.entity_ === null) return "profile";
    return `profile ${this.entity_.type}`;
  }

  /**
   * Returns the entity whose data is displayed.
   * @return The entity.
   */
  public get entity(): IEntityInfo {
    return this.entity_ === null
      ? this.$store.state.currentEntity
      : this.entity_;
  }

  /**
   * Sets the entity whose data will be displayed.
   * @param entity The entity to set.
   */
  public set entity(entity: IEntityInfo) {
    // Set the color and entity.
    this.entity_ = entity;
    this.color_ =
      this.entity_.type === "organization"
        ? { r: 255, g: 127, b: 232, a: 1.0 }
        : { r: 0, g: 202, b: 228, a: 1.0 };
    this.hasData = true;
  }

  /**
   * Draws attention to an element of the profile.
   */
  private applyFocus(): void {
    this.$nextTick(() => {
      // Handle a focus.
      switch (this.focus) {
        case "social-media": {
          let socialMedia: any = this.$refs.socialMedia as SocialMedia;
          socialMedia.editMode = true;
          // TODO: focus better.
          break;
        }
        case "photo": {
          let component: any = this.$refs.profile as EntityProfile;
          component.showDialog();
          break;
        }
      }
    });
  }

  /**
   * Loads the profile information.
   */
  private loadProfile() {
    this.hasData = false;
    let promise: Promise<IApiResponseWithData<IEntityInfo>>;

    // Locate the entity to render.
    if (this.id === 0 || this.id === null)
      promise = EntityServices.getCurrentEntity();
    else promise = EntityServices.getEntity(this.id);
    promise.then(response => {
      let roles = this.$store.state.currentEntity.roles;
      let id = this.$store.state.currentEntity.id;
      let claims = response.data.claims ? response.data.claims : [];
      this.entity = response.data;
      this.applyFocus();
      this.isAuthorized_ =
        roles.indexOf("administrator") !== -1 || claims.some(c => c === id);
    }); // TODO: Handle error
  }

  /**
   * Callback that responds to the id being changed.
   * @param newValue The new value for the id.
   */
  @Watch("id", { immediate: true })
  private onIdChanged(newValue: number) {
    this.loadProfile();
  }

  /**
   * Callback that responds to the entity being changed.
   * @param newValue The new value for the entity.
   */
  @Watch("entity_")
  private onEntityChanged(newValue: IEntityInfo) {
    if (this.entity_ === null || this.$store.state.currentEntity === null)
      return;
    if (this.entity_.id === this.$store.state.currentEntity.id)
      this.$store.state.currentEntity = this.entity_;
  }
}
