










import Vue from "vue";
import BuildYourNetwork from "@/components/home/BuildYourNetwork.vue";
import LatestUpdates from "@/components/home/LatestUpdates.vue";
import NextSteps from "@/components/home/NextSteps.vue";
import Feedback from "@/components/home/Feedback.vue";
import { Component } from "vue-property-decorator";

/**
 * Renders the home page.
 */
@Component({
  components: { LatestUpdates, NextSteps, Feedback, BuildYourNetwork }
})
export default class Home extends Vue {
  /**
   * Callback that responds to the component being mounted.
   */
  public mounted() {}
}
