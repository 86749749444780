
import ConnectionPane from "@/components/profile/ConnectionPane.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IConnectionPaneParams } from "./ConnectionPane.vue";
import { IConnectionInfo, IAction, ITag } from "../../types";

/**
 * Responsible for rendering a "follows" widget.
 */
@Component
export default class Follows extends ConnectionPane {
  // The parameters required to run this pane.
  protected parameters_: IConnectionPaneParams = {
    name: "Follow",
    plural: "Follows",
    type: "follow",
    filter: "person",
    entityMustExist: true,
    includeIncoming: false,
    includeOutgoing: true,
    conflictResolution: "to",
    metaDataInfo: []
  };

  /**
   * Returns the actions for the given connection.
   * @param connection The connection to get the actions for.
   * @return The actions for the given connection.
   */
  protected getActions(connection: IConnectionInfo): IAction[] {
    let actions: IAction[] = [];
    let canEdit = this.authorized;
    // If we are the owner of this request or the connection is bidirectional, we can delete the connection.
    if (
      (connection.bidirectional === true ||
        connection.from.id === this.entity.id) &&
      canEdit
    ) {
      actions.push({
        label: "Delete",
        tag: "delete",
        colorText: { r: 255, g: 255, b: 255, a: 1 },
        colorBackground: { r: 204, g: 51, b: 0, a: 1 },
        callback: (event, action) =>
          this.onClickAction(event, connection, action)
      });
    }
    return actions;
  }

  /**
   * Returns the tags for the given connection.
   * @param connection The connection to get the tags for.
   * @return The tags for the given connection.
   */
  protected getTags(connection: IConnectionInfo): ITag[] {
    return [];
  }

  /**
   * Callback that responds to an action button being clicked.
   * @param event The object that enables interaction with the event.
   * @param connection The connection that the action is connected to.
   * @param action The action that was clicked.
   */
  private onClickAction(
    event?: Event,
    connection?: IConnectionInfo,
    action?: IAction
  ): void {
    if (!event || !connection || !action) return;
    switch (action.tag) {
      case "delete": {
        if (
          connection.bidirectional === true &&
          connection.to.id === this.entity.id
        ) {
          if (connection.otherConnection === null)
            throw new Error(
              "The connection is bidirectional but no other connection could be found"
            );
          else this.removeConnection(connection.otherConnection);
        } else this.removeConnection(connection);
        break;
      }
      default:
        throw new Error(`Unexpected action '${action.tag}' encountered.`);
    }
  }
}
