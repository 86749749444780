

























































import Container from "@/components/shared/Container.vue";
import EntityListing from "@/components/shared/EntityListing.vue";
import LoadingIndicator from "@/components/shared/LoadingIndicator.vue";
import Button from "@/components/shared/Button.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IEntityInfo } from "../../types";

/**
 * Describes a function that responds to a button click.
 */
export interface IClickHandler {
  (event?: Event, section?: string): void;
}

/**
 * Responsible for rendering a "latest updates" widget.
 */
@Component({
  components: {
    EntityListing,
    LoadingIndicator,
    Container,
    Button
  }
})
export default class LatestUpdates extends Vue {
  /**
   * The callback that responds to clicking on a load more button.
   */
  @Prop({ default: null })
  public loadMore!: IClickHandler;

  /**
   * The list of known people.
   */
  @Prop({ default: () => null })
  public people!: IEntityInfo[];

  /**
   * The list of known organizations.
   */
  @Prop({ default: () => null })
  public organizations!: IEntityInfo[];

  /**
   * A flag that indicates if there is a search in progress for people.
   */
  @Prop({ default: false })
  public findingPeople!: boolean;

  /**
   * A flag that indicates if there is a search in progress for organizations.
   */
  @Prop({ default: false })
  public findingOrganizations!: boolean;

  /**
   * A flag that indicates if there is a search in progress for people.
   */
  @Prop({ default: false })
  public hasMorePeople!: boolean;

  /**
   * A flag that indicates if there is a search in progress for organizations.
   */
  @Prop({ default: false })
  public hasMoreOrganizations!: boolean;

  /**
   * The tabs.
   */
  public tabs: string[] = ["People", "Organizations"];

  /**
   * Callback that responds to a user clicking the edit profile button.
   * @param event The object that enables interaction with the event.
   */
  private onClickLoadMorePeople(event: Event) {
    if (this.loadMore !== null) this.loadMore(event, "person");
  }

  /**
   * Callback that responds to a user clicking the edit profile button.
   * @param event The object that enables interaction with the event.
   */
  private onClickLoadMoreOrganizations(event: Event) {
    if (this.loadMore !== null) this.loadMore(event, "organization");
  }
}
