


































import "cropperjs/dist/cropper.css";
import Container from "@/components/shared/Container.vue";
import Button from "@/components/shared/Button.vue";
import MultiSelect from "@/components/shared/MultiSelect.vue";
import { IOption } from "../shared/MultiSelect.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { IEntityInfo, IRgba } from "../../types";
import EntityServices from "../../services/EntityServices";

/**
 * Responsible for rendering a "socail media" widget.
 */
@Component({ components: { Container, Button, MultiSelect } })
export default class Needs extends Vue {
  /**
   * The color to use for this profile.
   */
  @Prop({
    default: () => {
      return { r: 0, g: 202, b: 228, a: 1.0 };
    }
  })
  public color!: IRgba;

  /**
   * A flag that indicates if the current user can make edits to this profile.
   */
  @Prop({ default: false })
  public authorized!: boolean;

  /**
   * The entity to render information for.
   */
  @Prop({ default: null })
  public entity!: IEntityInfo;

  // Describes the entity being edited.
  private editMode_: boolean = false;
  private editEntity_: IEntityInfo | null = null;

  /**
   * Indicates if the profile is for the currently logged in entity.
   * @return True if this is the current entity's profile, false otherwise.
   */
  public get isCurrentEntity(): boolean {
    if (this.entity === null) return false;
    return this.$store.state.currentEntity.id === this.entity.id;
  }

  /**
   * The known needs.
   * @return The needs.
   */
  public get needsList(): IOption[] {
    return [
      { label: "Customers", value: "customers" },
      { label: "Investment", value: "investment" },
      { label: "Networking", value: "networking" },
      { label: "Partners", value: "partners" },
      { label: "Talent", value: "talent" },
      { label: "Training", value: "training" },
      { label: "Other", value: "other" }
    ];
  }

  /**
   * A flag that indicaes if the user has selected any needs.
   * @return True if the user has selected needs, false otherwise.
   */
  public get hasNeeds(): boolean {
    if (this.entity === null) return false;
    let hasContext =
      "context" in this.entity && this.entity.context.trim() !== "";
    let hasNeeds = "needs" in this.entity && this.entity.needs.length > 0;
    return hasContext || hasNeeds;
  }

  /**
   * The label to show if there is no needs.
   * @return The label.
   */
  public get noNeedsLabel(): string {
    if (this.entity === null)
      return "The user has not made their needs available to the public.";
    if (this.entity.id === this.$store.state.currentEntity.id)
      return "You have not provided any needs.";
    switch (this.entity.type) {
      case "person":
        return `${this.entity.firstName} has not made their needs available to the public.`;
      case "organization":
        return `${this.entity.name} has not made their needs available to the public.`;
      default:
        return "The user has not made any of their needs available to the public.";
    }
  }

  /**
   * Converts the specified input to html.
   * @param input The string to convert.
   * @return The HTML.
   */
  public get needsHtml(): string {
    if (this.entity === null) return "";

    // If there is no context, try to concatenate the needs.
    if (!("context" in this.entity) || this.entity.context.trim() === "") {
      if (!("needs" in this.entity) || this.entity.needs.length === 0)
        return "";
      let prefix = "";
      if (this.entity.id === this.$store.state.currentEntity.id)
        prefix = "You need ";
      else {
        switch (this.entity.type) {
          case "person":
            prefix = `${this.entity.firstName} needs `;
            break;
          case "organization":
            prefix = `${this.entity.name} needs `;
            break;
          default:
            prefix = "The user needs ";
            break;
        }
      }
      let output = "";
      switch (this.entity.needs.length) {
        case 1:
          output = prefix + this.entity.needs[0];
          break;
        case 2:
          output =
            prefix + `${this.entity.needs[0]} and ${this.entity.needs[1]}`;
          break;
        default:
          output =
            prefix +
            this.entity.needs
              .slice(0, this.entity.needs.length - 1)
              .join(", ") +
            `, and ${this.entity.needs[this.entity.needs.length - 1]}`;
          break;
      }
      return `<p>${output}</p>`;
    }
    // TODO: Replace utf bullet with <ul><li>
    let lines = this.entity.context.split("\n");
    let output = "";
    let isList = false;
    for (let line of lines) {
      if (line.trim().length === 0) continue;
      // If this is a bulleted item ...
      if (line.startsWith("\u2022")) {
        if (!isList) {
          output += "<ul>";
          isList = true;
        }
        output += "<li>" + line.substring(1).trim() + "</li>";
      }
      // If this is a regular item ...
      else {
        if (isList) {
          output += "</ul>";
          isList = false;
        }
        output += "<p>" + line + "</p>";
      }
    }
    console.log(output);
    return output;
  }

  /**
   * Callback that responds to a user clicking the edit social media button.
   * @param event The object that enables interaction with the event.
   */
  private onClickEditNeeds(event: Event) {
    this.editEntity_ = Object.assign({}, this.entity);
    this.editMode_ = true;
  }

  /**
   * Callback that responds to a user clicking the edit social media button.
   * @param event The object that enables interaction with the event.
   */
  private onClickSaveEditNeeds(event: Event) {
    this.$emit("update:entity", this.editEntity_);
    if (this.editEntity_ === null) return;
    EntityServices.updateEntity(this.editEntity_)
      .then(result => {
        // TODO: Handle error and success.
      })
      .catch(error => {
        console.error(error);
      });
    this.editMode_ = false;
  }

  /**
   * Callback that responds to a user clicking the edit social media button.
   * @param event The object that enables interaction with the event.
   */
  private onClickCancelEditNeeds(event: Event) {
    this.editMode_ = false;
  }

  /**
   * Callback that responds to the entity being set.
   * @param newValue The new value for the entity.
   */
  @Watch("entity", { immediate: true })
  private onEntityChanged(newValue: IEntityInfo) {
    if (!("needs" in newValue) || !Array.isArray(newValue.needs)) {
      newValue.needs = [];
      this.$emit("update:entity", newValue);
    }
  }
}
