














import { Component, Prop, Vue } from "vue-property-decorator";
import AdminWidget from "./AdminWidget.vue";
import LoadingIndicator from "@/components/shared/LoadingIndicator.vue";
import EntityServices from "../../services/EntityServices";
import { IRgba } from "../../types";

/**
 * Responsible for handling an entity count widget.
 */
@Component({ components: { AdminWidget, LoadingIndicator } })
export default class EntityCount extends Vue {
  /**
   * The type of entity to query for.
   */
  @Prop({ default: null })
  public type!: string;

  /**
   * The color for this container.
   */
  @Prop({
    default: () => {
      return { r: 222, g: 89, b: 64, a: 1.0 };
    }
  })
  public color!: IRgba;

  // The number of users.
  private count_: number | null = null;

  /**
   * The color the loading indicator should be.
   * @return The color
   */
  public get loadingColor(): IRgba {
    return { r: 255, g: 255, b: 255, a: 1.0 };
  }

  /**
   * The last update time.
   * @return The time.
   */
  public get updateTime(): string {
    return "Last updated at " + new Date().toLocaleTimeString();
  }

  /**
   * Returns the name of the organization.
   * @return The name.
   */
  public get entityName(): string {
    switch (this.type) {
      case "person":
        return "Users";
      case "organization":
        return "Organizations";
      default:
        return "Entities";
    }
  }

  /**
   * Called when the widget is added to the DOM tree.
   */
  public mounted() {
    EntityServices.getEntityCount(this.type).then(results => {
      this.count_ = results.data;
    });
  }
}
