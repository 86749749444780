



















import { Component, Prop, Vue } from "vue-property-decorator";
import AdminWidget from "./AdminWidget.vue";
import ProgressIndicator from "@/components/shared/ProgressIndicator.vue";
import Button from "@/components/shared/Button.vue";
import EntityServices from "../../services/EntityServices";
import { IRgba, INeo4jConnectionMetaData } from "../../types";
import ConnectionServices from "../../services/ConnectionServices";

/**
 * Responsible for handling an team import widget.
 */
@Component({ components: { AdminWidget, ProgressIndicator, Button } })
export default class ImportTeam extends Vue {
  // The queue of file to load.
  private totalTeam_: number = 0;
  private teamQueue_: any[] = [];
  private status_: string | null = null;
  private buttonsEnabled_: boolean = true;

  /**
   * The color for the panel.
   * @return The color.
   */
  public get color(): IRgba {
    return { r: 89, g: 188, b: 180, a: 1.0 };
  }

  /**
   * The color of the info button.
   * @return The color.
   */
  public get infoButtonColor(): IRgba {
    return { r: 62, g: 112, b: 211, a: 1.0 };
  }

  /**
   * Returns the status text for the progress bar.
   * @return The status.
   */
  public get status(): string {
    if (this.status_ !== null) return this.status_;
    return this.totalTeam_ === 0
      ? "Choose a file to upload"
      : `Processed ${this.totalTeam_ - this.teamQueue_.length} of ${
          this.totalTeam_
        }`;
  }

  /**
   * Calculates the percentage of files that have been completed.
   * @return The percentage.
   */
  public get percent(): number {
    return this.totalTeam_ === 0
      ? 0
      : (this.totalTeam_ - this.teamQueue_.length) / this.totalTeam_;
  }

  /**
   * Default constructor.
   */
  public constructor() {
    super();
  }

  /**
   * Called when the widget is added to the DOM tree.
   */
  public mounted() {
    let fileInput = this.$el.querySelector("input[type=file]");
    if (!fileInput || !(fileInput instanceof HTMLElement)) return;
    fileInput.onchange = this.onFileSelected;
  }

  /**
   * Processes the next entity in the queue.
   */
  private async processTeamNeo4j() {
    try {
      let status = "";

      while (this.teamQueue_.length > 0) {
        // Read the entity.
        let entity = this.teamQueue_[0];
        this.teamQueue_.splice(0, 1);

        // Validate the entity.
        if ( !('graph' in entity) 
          || !('nodes' in entity.graph)
          || !('relationships' in entity.graph)
          || !Array.isArray(entity.graph.nodes)
          || !Array.isArray(entity.graph.relationships)
          || entity.graph.nodes.length !== 2
          || entity.graph.relationships.length !== 1
          || !('type' in entity.graph.relationships[0])
          || !('properties' in entity.graph.relationships[0])
          || !('labels' in entity.graph.nodes[0])
          || !('labels' in entity.graph.nodes[1])
          || !('properties' in entity.graph.nodes[0])
          || !('properties' in entity.graph.nodes[1])
          || !Array.isArray(entity.graph.nodes[0].labels)
          || !Array.isArray(entity.graph.nodes[1].labels)
          || entity.graph.nodes[0].labels.length !== 1
          || entity.graph.nodes[1].labels.length !== 1) {
          status = "Invalid Data"
          throw new Error("Invalid Data");
        } 

        // Find the entities.
        let fromEntity: any = null;
        let toEntity: any = null;
        let metaData: INeo4jConnectionMetaData | null = null;
        if (entity.graph.nodes[0].labels[0] === "User")
          fromEntity = entity.graph.nodes[0].properties;
        if (entity.graph.nodes[0].labels[0] === "Organization")
          toEntity = entity.graph.nodes[0].properties;
        if (entity.graph.nodes[1].labels[0] === "User")
          fromEntity = entity.graph.nodes[1].properties;
        if (entity.graph.nodes[1].labels[0] === "Organization")
          toEntity = entity.graph.nodes[1].properties;
        if (entity.graph.relationships[0].type === "EXPERIENCE")
          metaData = entity.graph.relationships[0].properties;
        if (fromEntity === null || toEntity === null || metaData === null) {
          status = "Invalid Data"
          throw new Error("Invalid Data");
        }

        // Do the import.
        let result = null;
        let retry = 0;
        while (retry < 3) {
          try {
            // Team imports from neo4j are bidirectional by nature.
            result = await ConnectionServices.importConnection(
              fromEntity, 
              toEntity, 
              metaData,
              'person',
              'organization',
              'team'
            );
            result = await ConnectionServices.importConnection(
              toEntity,
              fromEntity,
              {},
              'organization',
              'person',
              'team'
            );
            /*
            if (metaData.founder)
              result = await EntityServices.claimEntity(fromEntity, toEntity)*/
              console.log(result);
            break;
          } catch (e) {
            if ('message' in e && e.message === "Could not find the entities being connected") {
              console.warn(`Failed to import: ${fromEntity.first_name} ${fromEntity.last_name} (${fromEntity.email}) => ${toEntity.name}`);
              break;
            }
            retry++;
          }
        }
        if (retry === 3) {
          status = "Network Error";
          throw new Error("Network Error");
        }
      }
      this.teamQueue_ = [];
      this.totalTeam_ = 0;
      this.status_ = "Upload complete";
      this.buttonsEnabled_ = true;
    } catch (e) {
      this.teamQueue_ = [];
      this.totalTeam_ = 0;
      this.status_ = status.length === 0 ? "Failed to upload" : status;
      this.buttonsEnabled_ = true;
    }
  }

  /**
   * Callback that responds to a user clicking the "Upload Json" button.
   * @param event The object that enables interaction with the event.
   */
  private onClickUploadJson(event: Event) {
    event.preventDefault();
    let fileInput = this.$el.querySelector("input[type=file]");
    if (!fileInput || !(fileInput instanceof HTMLElement)) return;
    fileInput.click();
  }

  /**
   * Callback that responds to the file being selected.
   * @param event The object that enables interaction with the event.
   */
  private async onFileSelected(event: Event) {
    // Validate the input
    event.preventDefault();
    let fileInput = this.$el.querySelector("input[type=file]");
    if (
      !fileInput ||
      !(fileInput instanceof HTMLInputElement) ||
      !fileInput.files
    ) {
      this.status_ = "Error processing file";
      this.buttonsEnabled_ = true;
      return;
    }
    this.buttonsEnabled_ = false;

    // TODO: Prevent multiple files.
    if (fileInput.files.length === 1) {
      this.status_ = "Processing file ...";
      let file = fileInput.files[0];
      let reader = new FileReader();
      reader.readAsText(file, "UTF-8");
      reader.onload = evt => {
        // Queue the lines from the file.
        let target: FileReader | null =
          "target" in evt && evt.target instanceof FileReader
            ? evt.target
            : null;
        if (!target || !target.result || target.result instanceof ArrayBuffer) {
          this.status_ = "Error processing file";
          this.buttonsEnabled_ = true;
          return;
        }
        // TODO: Try Catch
        let result = JSON.parse(target.result);
        if ('data' in result && Array.isArray(result.data)) {
          this.totalTeam_ = result.data.length;
          this.teamQueue_ = result.data; 
          this.status_ = null;
          this.processTeamNeo4j();
        }
        else {
          this.status_ = "Error processing file";
          this.buttonsEnabled_ = true;
          return;
        }
      };
      reader.onerror = evt => {
        this.status_ = "Error processing file";
        this.buttonsEnabled_ = true;
      };
    } else {
      this.status_ = "Please select only 1 file";
      this.buttonsEnabled_ = true;
    }
    fileInput.value = "";
  }
}
