














































































import Container from "@/components/shared/Container.vue";
import ProgressIndicator from "@/components/shared/ProgressIndicator.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IEntityInfo } from "../../types";

/**
 * Responsible for rendering a "latest updates" widget.
 */
@Component({
  components: { Container, ProgressIndicator }
})
export default class NextSteps extends Vue {
  /**
   * A flag that indicates if there are next steps.
   * @return True if there are steps, false otherwise.
   */
  public get hasSteps(): boolean {
    return this.completedSteps > 0;
  }

  /**
   * Returns the number of completed steps.
   * @return The number of steps.
   */
  public get completedSteps(): number {
    return (
      (this.classEmail === "complete" ? 1 : 0) +
      (this.classTagline === "tagline" ? 1 : 0) +
      (this.classDescription === "complete" ? 1 : 0) +
      (this.classNeeds === "complete" ? 1 : 0) +
      (this.classProfilePhoto === "complete" ? 1 : 0) +
      (this.classExperience === "complete" ? 1 : 0)
    );
  }

  /**
   * Returns the total number of steps.
   * @return The total number of steps.
   */
  public get totalSteps(): number {
    return 6;
  }

  /**
   * Ther percentage of completeness.
   */
  public get percent(): number {
    // Note: We can not use (0.05) in place of (5 / 100) or we introduce a rounding error.
    let percent = this.completedSteps / this.totalSteps;
    return (Math.round(percent * 20) * 5) / 100;
  }

  /**
   * Returns the class name for the e-mail step;
   * @return The class name.
   */
  public get classEmail(): string {
    let entity: IEntityInfo = this.$store.state.currentEntity;
    return entity && entity.verified === "true" ? "complete" : "";
  }

  /**
   * Returns the class name for the tagline step;
   * @return The class name.
   */
  public get classTagline(): string {
    let entity: IEntityInfo = this.$store.state.currentEntity;
    return entity.tagline && entity.tagline.trim() !== "" ? "complete" : "";
  }

  /**
   * Returns the class name for the description step;
   * @return The class name.
   */
  public get classDescription(): string {
    let entity: IEntityInfo = this.$store.state.currentEntity;
    return entity.description && entity.description.trim() !== ""
      ? "complete"
      : "";
  }

  /**
   * Returns the class name for the needs step;
   * @return The class name.
   */
  public get classNeeds(): string {
    let entity: IEntityInfo = this.$store.state.currentEntity;
    return entity.needs && entity.needs.length > 0 ? "complete" : "";
  }

  /**
   * Returns the class name for the profile photo step;
   * @return The class name.
   */
  public get classProfilePhoto(): string {
    let entity: IEntityInfo = this.$store.state.currentEntity;
    if ("imgSrc" in entity && entity.imgSrc.trim() !== "") return "complete";
    return "";
  }

  /**
   * Returns the class name for the experience step.
   * @return The class name.
   */
  public get classExperience(): string {
    return "";
  }
}
