import store from "@/store/index";

// The zip instance.
let zip: any = null;
if ("zip" in window) zip = (window as any).zip;
else throw new Error("Could not load zip.js");

/**
 * Describes an object that contains zip and unzip methods.
 */
export class ZipJs {
  /**
   * The path to the worker scripts.
   * @return The path.
   */
  public static get workerScriptsPath(): string {
    return zip.workerScriptsPath;
  }

  /**
   * The path to the worker scripts.
   * @param newValue The path.
   */
  public static set workerScriptsPath(newValue: string) {
    zip.workerScriptsPath = newValue;
  }
}

/**
 * Describes an object used to read blobs.
 */
export class ZipReader {
  /**
   * Reads the specified file.
   * @param file The file to read.
   * @return The awaitable promise.
   */
  public async read(file: File): Promise<ZipEntry[]> {
    return new Promise<any[]>((resolve, reject) => {
      zip.createReader(
        new zip.BlobReader(file),
        (zipReader: any) => {
          zipReader.getEntries((entries: any) => {
            let output: ZipEntry[] = [];
            for (let entry of entries) {
              output.push(new DefaultZipEntry(entry));
            }
            resolve(output);
          });
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }
}

/**
 * Describes an object that contains methods for working with a single entry in a zip file.
 */
export abstract class ZipEntry {
  /**
   * Constructor only visible to child classes.
   */
  protected constructor() {}

  /**
   * The filename of the entry.
   */
  public abstract get filename(): string;

  /**
   * The size of the file as compressed data.
   */
  public abstract get compressedSize(): number;

  /**
   * The size of the file after being uncompressed.
   */
  public abstract get uncompressedSize(): number;

  /**
   * The number of bytes from the beginning of the archive where the file exists.
   */
  public abstract get offsetSize(): number;

  /**
   * Returns the text for the entry.
   * @return The text.
   */
  public abstract async getText(): Promise<string>;

  /**
   * Returns the data for the entry.
   * @return The data.
   */
  public abstract async getData(): Promise<ArrayBuffer>;
}

/**
 * The default implementation of ZipEntry.
 */
class DefaultZipEntry extends ZipEntry {
  /**
   * The filename of the entry.
   */
  public get filename(): string {
    return this.entry_.filename;
  }

  /**
   * The size of the file as compressed data.
   */
  public get compressedSize(): number {
    return this.entry_.compressedSize;
  }

  /**
   * The size of the file after being uncompressed.
   */
  public get uncompressedSize(): number {
    return this.entry_.uncompressedSize;
  }

  /**
   * The number of bytes from the beginning of the archive where the file exists.
   */
  public get offsetSize(): number {
    return this.entry_.offset;
  }

  private readonly entry_: any;

  /**
   * Constructs the entry by providing the entry being wrapped.
   * @param entry The entry to wrap.
   */
  public constructor(entry: any) {
    super();
    this.entry_ = entry;
  }

  /**
   * Returns the data for the entry.
   * @return The data.
   */
  public async getText(): Promise<string> {
    return new Promise<string>((accept, reject) => {
      let writer = new zip.BlobWriter();
      this.entry_.getData(
        writer,
        (blob: any) => {
          console.log(blob);
          blob
            .text()
            .then((text: string) => {
              accept(text);
            })
            .catch((error: any) => {
              reject(error);
            });
        },
        (progress: any) => {
          // TODO: something with this.
        }
      );
    });
  }

  /**
   * Returns the data for the entry.
   * @return The data.
   */
  public async getData(): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((accept, reject) => {
      let writer = new zip.ArrayBufferWriter();
      this.entry_.getData(
        writer,
        (data: any) => {
          accept(data);
        },
        (progress: any) => {
          // TODO: something with this.
        }
      );
    });
  }
}
