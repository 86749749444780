











import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "@/components/shared/Button.vue";
import SearchTextBox from "@/components/shared/SearchTextBox.vue";

/**
 * Responsible for handling the search bar.
 */
@Component({ components: { Button, SearchTextBox } })
export default class SearchBar extends Vue {
  /**
   * The text to use for the placeholder.
   */
  @Prop({ default: null })
  public placeholder!: string;

  // The text in the textbox.
  private text_: string = "";

  /**
   * The source for the search image.
   * @return The source.
   */
  public get imageSrc(): string {
    return this.$store.getters.imageSrc("icon-search.svg");
  }

  /**
   * Callback that responds to the user clicking on the search button.
   */
  private onClickSearch() {
    if (
      this.$router.currentRoute.name != "search" ||
      (this.$router.currentRoute.query as any).query !== `.${this.text_}.`
    )
      this.$router.replace(`/search?query=.${this.text_}.&fields=name`);
  }
}
