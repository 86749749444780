



















import { Component, Prop, Vue } from "vue-property-decorator";
import AdminWidget from "./AdminWidget.vue";
import ProgressIndicator from "@/components/shared/ProgressIndicator.vue";
import Button from "@/components/shared/Button.vue";
import EntityServices from "../../services/EntityServices";
import { ZipJs, ZipReader } from "../../zipjs/index";
import { IRgba } from "../../types";

/**
 * Responsible for handling an entity import widget.
 */
@Component({ components: { AdminWidget, ProgressIndicator, Button } })
export default class ImportAvatar extends Vue {
  // The queue of file to load.
  private totalAvatars_: number = 0;
  private avatarsLoaded_: number = 0;
  private status_: string | null = null;
  private buttonsEnabled_: boolean = true;

  /**
   * The color for the panel.
   * @return The color.
   */
  public get color(): IRgba {
    return { r: 89, g: 188, b: 180, a: 1.0 };
  }

  /**
   * The color of the info button.
   * @return The color.
   */
  public get infoButtonColor(): IRgba {
    return { r: 62, g: 112, b: 211, a: 1.0 };
  }

  /**
   * Returns the status text for the progress bar.
   * @return The status.
   */
  public get status(): string {
    if (this.status_ !== null) return this.status_;
    return this.totalAvatars_ === 0
      ? "Choose a file to upload"
      : `Processed ${this.avatarsLoaded_} of ${this.totalAvatars_}`;
  }

  /**
   * Calculates the percentage of files that have been completed.
   * @return The percentage.
   */
  public get percent(): number {
    return this.totalAvatars_ === 0
      ? 0
      : this.avatarsLoaded_ / this.totalAvatars_;
  }

  /**
   * Default constructor.
   */
  public constructor() {
    super();
  }

  /**
   * Called when the widget is added to the DOM tree.
   */
  public mounted() {
    let fileInput = this.$el.querySelector("input[type=file]");
    if (!fileInput || !(fileInput instanceof HTMLElement)) return;
    fileInput.onchange = this.onFileSelected;
  }

  /**
   * Callback that responds to a user clicking the edit profile button.
   * @param event The object that enables interaction with the event.
   */
  private onClickUploadZip(event: Event) {
    event.preventDefault();
    let fileInput = this.$el.querySelector("input[type=file]");
    if (!fileInput || !(fileInput instanceof HTMLElement)) return;
    fileInput.click();
  }

  /**
   * Callback that responds to the file being selected.
   * @param event The object that enables interaction with the event.
   */
  private async onFileSelected(event: Event) {
    // Validate the input
    event.preventDefault();
    let fileInput = this.$el.querySelector("input[type=file]");
    if (
      !fileInput ||
      !(fileInput instanceof HTMLInputElement) ||
      !fileInput.files
    ) {
      this.status_ = "Error processing file";
      this.buttonsEnabled_ = true;
      return;
    }
    this.buttonsEnabled_ = false;

    // Process the zip file.
    for (let i = 0; i < fileInput.files.length; i++) {
      let file = fileInput.files[i];
      let reader = new ZipReader();

      try {
        let entries = await reader.read(file);
        this.totalAvatars_ = entries.length;
        for (let entry of entries) {
          // TODO: Simplify using regex
          if (
            !entry.filename.toLowerCase().endsWith("jpg") &&
            !entry.filename.toLowerCase().endsWith("jpeg") &&
            !entry.filename.toLowerCase().endsWith("png")
          )
            continue;

          // Make sure there is a valid id.
          let lastIndex = entry.filename.lastIndexOf("/");
          if (lastIndex === -1) continue;
          let name = entry.filename.substring(lastIndex + 1);
          let id = entry.filename.substring(0, lastIndex);
          lastIndex = id.lastIndexOf("/");
          if (lastIndex !== -1) id = id.substring(lastIndex + 1);

          // Make sure this is the full sized image.
          if (
            name.toLowerCase().startsWith("profile_") ||
            name.toLowerCase().startsWith("thumbnail_") ||
            name.toLowerCase().startsWith("mini_")
          )
            continue;

          // If we got here, the file is valid.
          try {
            let data = await entry.getData();
            let response = await EntityServices.importProfileImage(id, data);
            this.avatarsLoaded_++;
          } catch (error) {
            this.avatarsLoaded_++;
          }
        }
        this.status_ = "Upload complete";
        this.totalAvatars_ = 0;
        this.avatarsLoaded_ = 0;
        this.buttonsEnabled_ = true;
      } catch (error) {
        this.status_ = "Error processing file";
        this.buttonsEnabled_ = true;
      }
    }
  }
}
