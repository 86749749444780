








import Container from "@/components/shared/Container.vue";
import PostListing from "./PostListing.vue";
import LoadingIndicator from "@/components/shared/LoadingIndicator.vue";
import ErrorIndicator from "@/components/shared/ErrorIndicator.vue";

import PostServices from "../../services/PostServices";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IPostInfo } from "../../types";

/**
 * Responsible for rendering a "latest updates" widget.
 */
@Component({
  components: { PostListing, LoadingIndicator, ErrorIndicator, Container }
})
export default class LatestUpdates extends Vue {
  /**
   * The list of updates.
   */
  public posts: IPostInfo[] = [];

  private hasData_: boolean = false;
  private hasError_: boolean = false;
  private errorCode_: number = 500;

  /**
   * Callback that responds to this component being added to the DOM.
   */
  public mounted() {
    PostServices.getLatestPosts()
      .then(result => {
        this.posts = result.data;
        this.hasData_ = true;
      })
      .catch(error => {
        this.hasError_ = true;
        this.errorCode_ = error.code;
      });
  }
}
