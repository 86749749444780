






















import Container from "@/components/shared/Container.vue";
import ProgressIndicator from "@/components/shared/ProgressIndicator.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IEntityInfo } from "../../types";

/**
 * Responsible for rendering a "latest updates" widget.
 */
@Component({
  components: { Container, ProgressIndicator }
})
export default class BuildYourNetwork extends Vue {}
