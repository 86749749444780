
























































import "cropperjs/dist/cropper.css";
import Container from "@/components/shared/Container.vue";
import Button from "@/components/shared/Button.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IEntityInfo, IRgba } from "../../types";
import EntityServices from "../../services/EntityServices";

/**
 * Describes an object that collects information about a media item.
 */
interface IMediaItem {
  imageSrc: string;
  imageAlt: string;
  url: string;
  label: string;
}

/**
 * Responsible for rendering a "socail media" widget.
 */
@Component({ components: { Container, Button } })
export default class SocialMedia extends Vue {
  /**
   * The color to use for this profile.
   */
  @Prop({
    default: () => {
      return { r: 0, g: 202, b: 228, a: 1.0 };
    }
  })
  public color!: IRgba;

  /**
   * A flag that indicates if the current user can make edits to this profile.
   */
  @Prop({ default: false })
  public authorized!: boolean;

  /**
   * The entity to render information for.
   */
  @Prop({ default: null })
  public entity!: IEntityInfo;

  // Describes the entity being edited.
  private editMode_: boolean = false;
  private editEntity_: IEntityInfo | null = null;

  /**
   * Indicates if the profile is for the currently logged in entity.
   * @return True if this is the current entity's profile, false otherwise.
   */
  public get isCurrentEntity(): boolean {
    if (this.entity === null) return false;
    return this.$store.state.currentEntity.id === this.entity.id;
  }

  /**
   * The label to show if there is no media.
   * @return The label.
   */
  public get noMediaLabel(): string {
    if (this.entity === null)
      return "The user has not made their contact information available to the public.";
    if (this.entity.id === this.$store.state.currentEntity.id)
      return "You have not provided any contact information.";
    switch (this.entity.type) {
      case "person":
        return `${this.entity.firstName} has not made their contact information available to the public.`;
      case "organization":
        return `${this.entity.name} has not made their contact information available to the public.`;
      default:
        return "The user has not made any of their contact information available to the public.";
    }
  }

  /**
   * All known media items.
   */
  public get media(): IMediaItem[] {
    let items: IMediaItem[] = [];
    if (this.entity === null) return items;
    if (
      "facebookUsername" in this.entity &&
      this.entity.facebookUsername.trim() !== ""
    ) {
      items.push({
        url: `https://www.facebook.com/${this.entity.facebookUsername}`,
        label: `facebook.com/${this.entity.facebookUsername}`,
        imageSrc: this.$store.getters.imageSrc("icon-social-facebook.svg"),
        imageAlt: "Facebook icon"
      });
    }
    if ("linkedinUrl" in this.entity && this.entity.linkedinUrl.trim() !== "") {
      items.push({
        url: `${this.entity.linkedinUrl}`,
        label: `${this.shortenUrl(this.entity.linkedinUrl)}`,
        imageSrc: this.$store.getters.imageSrc("icon-social-linkedin.svg"),
        imageAlt: "LinkedIn icon"
      });
    }
    if (
      "twitterUsername" in this.entity &&
      this.entity.twitterUsername.trim() !== ""
    ) {
      items.push({
        url: `https://twitter.com/${this.entity.twitterUsername}`,
        label: this.entity.twitterUsername.startsWith('@') ? this.entity.twitterUsername : `@${this.entity.twitterUsername}`,
        imageSrc: this.$store.getters.imageSrc("icon-social-twitter.svg"),
        imageAlt: "Twitter icon"
      });
    }
    if ("website" in this.entity && this.entity.website.trim() !== "") {
      items.push({
        url: `${this.entity.website}`,
        label: `${this.shortenUrl(this.entity.website)}`,
        imageSrc: this.$store.getters.imageSrc("icon-social-web.svg"),
        imageAlt: "Web icon"
      });
    }
    if ("email" in this.entity && this.entity.email.trim() !== "") {
      items.push({
        url: `mailto:${this.entity.email}`,
        label: `${this.entity.email}`,
        imageSrc: this.$store.getters.imageSrc("icon-social-email.svg"),
        imageAlt: "Email icon"
      });
    }
    return items;
  }

  /**
   * Indicates if the social media is being edited.
   * @return True if the social media is being edited, false otherwise.
   */
  public get editMode(): boolean {
    return this.editMode_;
  }

  /**
   * Indicates if the social media is being edited.
   * @param newValue True if the social media is being edited, false otherwise.
   */
  public set editMode(newValue: boolean) {
    if (newValue) {
      this.editEntity_ = Object.assign({}, this.entity);
      this.editMode_ = true;
    } else {
      this.editEntity_ = null;
      this.editMode_ = false;
    }
  }

  /**
   * Callback that responds to a user clicking the edit social media button.
   * @param event The object that enables interaction with the event.
   */
  private onClickEditSocialMedia(event: Event) {
    this.editMode = true;
  }

  /**
   * Callback that responds to a user clicking the edit social media button.
   * @param event The object that enables interaction with the event.
   */
  private onClickSaveEditSocialMedia(event: Event) {
    this.$emit("update:entity", this.editEntity_);
    if (this.editEntity_ === null) return;
    // Filter the results.
    if (this.editEntity_.twitterUsername)
      EntityServices.updateEntity(this.editEntity_)
        .then(result => {
          // TODO: Handle error and success.
        })
        .catch(error => {
          console.error(error);
        });
    this.editMode = false;
  }

  /**
   * Callback that responds to a user clicking the edit social media button.
   * @param event The object that enables interaction with the event.
   */
  private onClickCancelEditSocialMedia(event: Event) {
    this.editMode = false;
  }

  /**
   * Removes unessicary elements from a url.
   * @param url The url to shorten.
   * @return The shortened url.
   */
  private shortenUrl(url: string): string {
    return url.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "");
  }
}
